import React from 'react';

type Props = {
  className?: string;
}

const Icon: React.FC<Props> = ({ className }: Props) =>
  <svg className={className} width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.0429 33C12.6687 32.9939 8.47533 31.2536 5.3823 28.1606C2.28926 25.0675 0.548916 20.8742 0.542847 16.5H2.8369C2.84358 20.0704 4.19718 23.5068 6.6272 26.1226C9.05722 28.7385 12.3847 30.3411 15.9449 30.6104C19.5051 30.8797 23.0359 29.7957 25.8317 27.5751C28.6276 25.3545 30.4825 22.1608 31.0261 18.6321C31.5697 15.1033 30.7619 11.4994 28.764 8.54029C26.7661 5.58122 23.7253 3.48493 20.2491 2.67024C16.773 1.85555 13.1174 2.38245 10.0128 4.14568C6.90815 5.90891 4.5831 8.77859 3.5022 12.1814L1.31708 11.4875C2.2426 8.5849 3.95368 5.99522 6.2607 4.00542C8.56772 2.01561 11.3806 0.703379 14.3876 0.214144C17.3947 -0.275091 20.4785 0.0777642 23.2973 1.23362C26.1161 2.38947 28.5598 4.30318 30.3578 6.76269C32.1557 9.2222 33.2375 12.1314 33.4835 15.1681C33.7294 18.2047 33.1298 21.2502 31.7511 23.9669C30.3724 26.6837 28.2684 28.9657 25.6723 30.5601C23.0763 32.1545 20.0895 32.999 17.0429 33V33Z" fill="#333333" />
    <path d="M11.8008 24.0818H22.0323V21.9827H14.7487L14.9322 21.7476C15.1954 21.4293 15.3906 21.0604 15.5057 20.6637C15.5935 20.1774 15.6339 19.6838 15.6262 19.1897L15.5745 17.8305H19.2622V16.001H15.5114L15.4771 13.1678C15.4556 12.5919 15.6118 12.0232 15.9244 11.539C16.059 11.3244 16.2471 11.1486 16.4704 11.0289C16.6937 10.9092 16.9444 10.8498 17.1976 10.8565C17.444 10.8493 17.6893 10.8919 17.9188 10.9815C18.1484 11.0712 18.3576 11.2062 18.5339 11.3785C18.8908 11.7757 19.0799 12.2957 19.0615 12.8294H21.7972C21.827 12.2882 21.7399 11.7468 21.542 11.2422C21.3441 10.7375 21.0399 10.2813 20.6502 9.90453C19.6964 9.08848 18.4617 8.67694 17.2091 8.7575C16.6046 8.72799 16.0003 8.82099 15.4326 9.03092C14.8649 9.24084 14.3455 9.56336 13.9056 9.97909C13.4965 10.3968 13.1767 10.8933 12.9657 11.4386C12.7547 11.9839 12.6569 12.5663 12.6783 13.1506L12.7127 16.0182H11.0839V17.8477H12.7644L12.8217 19.2069C12.8193 19.8079 12.7578 20.4073 12.6381 20.9963C12.5942 21.2215 12.4931 21.4316 12.3444 21.6064C12.1958 21.7812 12.0046 21.9148 11.7893 21.9942V21.9942L11.8008 24.0818Z" fill="#333333" />
  </svg>


export default Icon;
