'use client';

import { FormEvent, useContext, useState } from 'react';
import clsx from 'clsx';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import FNCInput from '~/components/furniturechoice/fnc/fnc-input';
import FNCLinkButton from '~/components/furniturechoice/fnc/fnc-link-button';
import Link from '~/components/furniturechoice/link';
import { useAccount } from '~/frontastic/hooks';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import LoginErrorModal from './login-error-modal';

export default function CustomerContentManager() {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const { login } = useAccount();
  const [isModalHidden, setModalHidden] = useState<boolean>(true);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const payload = Object.fromEntries(formData);
    let response;

    try {
      response = await executeWithProgress(() => login(payload['email'] as string, payload['password'] as string));

      if (response.accountId) {
        location.assign('/checkout/information');
      } else {
        setModalHidden(false);
      }
    } catch {
      setModalHidden(false);
    }
  }

  return (
    <section className="container mb-20 mt-10 grid grid-cols-1 gap-y-5 lg:mt-4 lg:grid-cols-[1fr,fit-content(15px),1fr] lg:gap-x-7">
      <div className="surface-grey-1 flex h-fit flex-col items-center gap-y-5 px-4 py-5 lg:w-full lg:max-w-[431px] lg:items-start lg:justify-self-end">
        <h2 className="font-sans text-20 font-bold">
          {formatMessage({ id: 'tastics.checkout.customer.customer-content-manager.new_customer' })}
        </h2>

        <FNCLinkButton className="surface-green-2 min-h-[55px] w-full rounded-sm" href="/checkout/information">
          {formatMessage({ id: 'tastics.checkout.customer.customer-content-manager.continue_as_guest' })}
        </FNCLinkButton>

        <p className="text-12 text-grey-4">
          {formatMessage({
            id: 'tastics.checkout.customer.customer-content-manager.you_can_register_your_account_later',
          })}
        </p>
      </div>

      <div
        className={clsx(
          'flex items-center gap-x-4 gap-y-1 text-grey-4',
          'before:flex before:h-px before:flex-1 before:bg-grey-3 before:content-[""]',
          'after:flex after:h-px after:flex-1 after:bg-grey-3 after:content-[""]',
          'lg:w-fit lg:flex-col lg:before:h-auto lg:before:w-px lg:after:h-auto lg:after:w-px',
        )}
      >
        {formatMessage({ id: 'tastics.checkout.customer.customer-content-manager.or' })}
      </div>

      <div className="flex h-fit flex-col items-center gap-y-5 px-4 py-5 shadow lg:w-full lg:max-w-[431px] lg:items-start lg:justify-self-start">
        <h2 className="font-sans text-20 font-bold">
          {formatMessage({ id: 'tastics.checkout.customer.customer-content-manager.existing_customer' })}
        </h2>

        <form method="post" className="flex w-full flex-col gap-y-3" onSubmit={handleSubmit}>
          <FNCInput className="w-full" type="email" label="Email" name="email" required />
          <FNCInput className="w-full" type="password" label="Password" name="password" required />
          <FNCButton className="surface-green-2 min-h-[55px] w-full rounded-sm" type="submit">
            {formatMessage({ id: 'tastics.checkout.customer.customer-content-manager.sign_in' })}
          </FNCButton>
        </form>

        <Link className="self-start text-12 text-grey-4 underline" href="/">
          {formatMessage({ id: 'tastics.checkout.customer.customer-content-manager.forgot_your_password?' })}
        </Link>
      </div>
      <LoginErrorModal isOpen={!isModalHidden} onModalClose={() => setModalHidden(true)} />
    </section>
  );
}
