'use client';

import { ReactNode, useState } from 'react';
import clsx from 'clsx';
import { getTrackBackground, Range } from 'react-range';
import { IThumbProps, ITrackProps } from 'react-range/lib/types';

interface FilterRangeSliderProp {
  min: number;
  max: number;
  onSliderChange: (values: number[], initailMinVal: number, initialMaxVal: number) => void;
  minSelected?: number;
  maxSelected?: number;
}

export default function FilterRangeSlider({
  min,
  max,
  minSelected,
  maxSelected,
  onSliderChange,
}: FilterRangeSliderProp): JSX.Element {
  const [values, setValues] = useState<number[]>([minSelected ?? min, maxSelected ?? max]);
  const minDistance = 25;

  if (maxSelected && maxSelected > max) {
    max = maxSelected;
  }

  if (minSelected && minSelected < min) {
    min = minSelected;
  }

  const handleSliderChange = (newValues: number[]) => {
    const [firstValue, secondValue] = newValues;

    if (secondValue - firstValue >= minDistance) {
      setValues(newValues);
    } else {
      if (firstValue + minDistance <= max) {
        setValues([firstValue, firstValue + minDistance]);
      } else if (secondValue - minDistance >= min) {
        setValues([secondValue - minDistance, secondValue]);
      }
    }
  };

  function TrackElement(props: ITrackProps, children: ReactNode) {
    return (
      <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} className={clsx('flex h-9 w-full')}>
        <div
          className={clsx('h-0.5 w-full self-center rounded')}
          ref={props.ref}
          style={{
            background: getTrackBackground({
              values,
              colors: ['#ccc', '#51823C', '#ccc'],
              min: min,
              max: max,
            }),
          }}
        >
          {children}
        </div>
      </div>
    );
  }

  function ThumbElement(index: number, props: IThumbProps) {
    return (
      <div
        {...props}
        key={props.key}
        className={clsx('flex h-[11px] w-[11px] items-center justify-center rounded-full bg-green-2')}
      >
        <div className="absolute -top-7 rounded-md border border-grey-5 px-1 text-14 font-light leading-4.5 text-grey-5">
          {values[index]}
        </div>
      </div>
    );
  }

  return (
    <Range
      step={1}
      min={min}
      max={max}
      values={values}
      allowOverlap={false}
      onChange={handleSliderChange}
      onFinalChange={(newValues) => onSliderChange(newValues, minSelected ?? min, maxSelected ?? max)}
      renderTrack={({ props, children }) => TrackElement(props, children)}
      renderThumb={({ index, props }) => ThumbElement(index, props)}
    />
  );
}
