'use client';

import { useId, useRef, useState } from 'react';
import clsx from 'clsx';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { SwiperContainer } from 'swiper/element';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import Link from '~/components/furniturechoice/link';
import { ImageProps } from '~/frontastic/lib/image';
import useSwiper from '~/helpers/hooks/furniturechoice/use-swiper';
import { Reference } from '~/types/reference';
import { Variation } from '../..';

interface SliderProps {
  image: ImageProps;
  reference: Reference;
  variations: Variation[];
}

export default function Slider({ image, reference, variations }: SliderProps) {
  const [isLeftBlurVisible, setIsLeftBlurVisible] = useState<boolean>(false);
  const [isRightBlurVisible, setIsRightBlurVisible] = useState<boolean>(true);
  const prevSlideButtonId = useId();
  const nextSlideButtonId = useId();
  const swiperEl = useRef<SwiperContainer>(null);
  const { isInitialized } = useSwiper({
    ref: swiperEl,
    props: {
      breakpoints: {
        0: {
          slidesOffsetBefore: 16,
          slidesOffsetAfter: 16,
          spaceBetween: 2,
          scrollbar: { enabled: false },
        },
        1024: {
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          spaceBetween: 20,
          scrollbar: { enabled: true },
          navigation: {
            // Use data-id instead of id because you can't query "#<useId()>"
            prevEl: `[data-id="${prevSlideButtonId}"]`,
            nextEl: `[data-id="${nextSlideButtonId}"]`,
            enabled: true,
          },
        },
      },
      on: {
        slideChange(swiper) {
          setIsLeftBlurVisible(!swiper.isBeginning);
          setIsRightBlurVisible(!swiper.isEnd);
        },
      },
    },
  });

  return (
    <div className="lg:container lg:flex lg:gap-x-1">
      <div className="hidden aspect-square min-w-[300px] max-w-[300px] lg:block">
        <Link reference={reference}>
          <CoFeImage className="object-cover" asset={image.media} colSpanPercentLg={0.25} />
        </Link>
      </div>

      <button
        className="color-change-transition my-auto hidden h-10 w-10 items-center justify-center text-grey-5 disabled:text-grey-2 lg:flex [&.swiper-button-lock]:invisible"
        data-id={prevSlideButtonId}
      >
        <MdOutlineKeyboardArrowLeft className="text-40" data-id={prevSlideButtonId} />
      </button>

      <div className="relative flex items-center overflow-x-hidden">
        <div
          className={clsx(
            'fade-in-initial absolute inset-y-0 left-0 z-10 w-[48px] bg-[linear-gradient(to_left,rgba(255,255,255,0),rgba(255,255,255,.5),#fff)]',
            { 'fade-in-enter': isLeftBlurVisible },
          )}
        />

        <swiper-container
          ref={swiperEl}
          // @ts-ignore: React doesn't support className for web components
          class={clsx(
            'mx-0 flex h-full gap-x-0.5 overflow-x-hidden lg:gap-x-5',
            { 'max-lg:container': !isInitialized },
            // eslint-disable-next-line prettier/prettier
            '[--swiper-scrollbar-bg-color:#eeeeee] [--swiper-scrollbar-drag-bg-color:#656665] [--swiper-scrollbar-size:1px]',
          )}
          init={false}
          slides-per-view="auto"
          scrollbar={true}
        >
          <swiper-slide
            // @ts-ignore: React doesn't support className for web components
            class="aspect-square min-w-[230px] max-w-[230px] lg:hidden"
          >
            <Link reference={reference}>
              <CoFeImage className="object-cover" asset={image.media} colSpanPercent={0.7} />
            </Link>
          </swiper-slide>

          {variations.map((variation) => (
            <swiper-slide
              // @ts-ignore: React doesn't support className for web components
              class="flex aspect-[250/230] min-w-[250px] max-w-[250px] items-center"
              key={variation.name}
            >
              <Link className="flex flex-col items-center gap-y-2.5" reference={variation.reference}>
                <CoFeImage
                  className="aspect-[250/187.5] object-cover"
                  asset={variation.image.media}
                  colSpanPercent={0.75}
                  colSpanPercentLg={0.25}
                />
                <p className="text-14">{variation.name}</p>
              </Link>
            </swiper-slide>
          ))}

          {/* @ts-ignore: React doesn't support className for web components */}
          <swiper-slide class="flex aspect-[171/230] min-w-[171px] max-w-[171px] items-center justify-center">
            <Link reference={reference}>{'View collections >'}</Link>
          </swiper-slide>
        </swiper-container>

        <div
          className={clsx(
            'fade-in-initial absolute inset-y-0 right-0 z-10 w-[48px] bg-[linear-gradient(to_right,rgba(255,255,255,0),rgba(255,255,255,.5),#fff)]',
            { 'fade-in-enter': isRightBlurVisible },
          )}
        />
      </div>

      <button
        className="color-change-transition my-auto ml-10 hidden h-10 w-10 items-center justify-center text-grey-5 disabled:text-grey-2 lg:flex [&.swiper-button-lock]:invisible"
        data-id={nextSlideButtonId}
      >
        <MdOutlineKeyboardArrowRight className="text-40" data-id={nextSlideButtonId} />
      </button>
    </div>
  );
}
