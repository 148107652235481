import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import ReactMarkdown, { Components } from 'react-markdown';

interface RichTextContentfulProps extends HTMLAttributes<HTMLDivElement> {
  data: string;
  components?: Components;
}

export default function RichTextMarkdown({
  className,
  data,
  components,
  ...props
}: RichTextContentfulProps): JSX.Element {
  const defaultComponents: Components = {
    a({ children, ...props }) {
      return (
        <a className="text-salmon-4 hover:underline" {...props}>
          {children}
        </a>
      );
    },

    p({ children }) {
      return <p className="leading-5">{children}</p>;
    },

    ul({ children }) {
      return <ul className="pl-4">{children}</ul>;
    },

    li({ children }) {
      return <li className="list-['—'] ps-1.5 marker:text-salmon-4">{children}</li>;
    },

    ...components,
  };
  return (
    <div className={clsx('flex flex-col gap-4 text-grey-5 lg:gap-6', className)} {...props}>
      <ReactMarkdown components={defaultComponents}>{data}</ReactMarkdown>
    </div>
  );
}
