'use client';

import { MdOutlineArrowForward } from 'react-icons/md';
import FNCLinkButton from '~/components/furniturechoice/fnc/fnc-link-button';
import Link from '~/components/furniturechoice/link';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import { Reference } from '~/types/reference';
import Collection from './collection';

export type Variation = {
  name: string;
  image: ImageProps;
  reference: Reference;
};

export type Collection = {
  name: string;
  supportingText: string;
  image: ImageProps;
  reference: Reference;
  variations: Variation[];
};

interface CollectionVariationSlidersProps {
  shopAllCtaText: string;
  shopAllCtaReference: Reference;
  collections: Collection[];
}

export default function CollectionVariationSliders({
  data: { shopAllCtaText, shopAllCtaReference, collections },
}: TasticProps<CollectionVariationSlidersProps>) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <section className="mb-16">
      <div className="container mb-4 flex items-center py-4">
        <Link className="text-14 text-salmon-4" reference={shopAllCtaReference}>
          {`${shopAllCtaText} >`}
        </Link>

        <p className="ml-auto text-14">{`${collections.length} ${formatMessage({
          id: 'tastics.category.collection.collection-variation-sliders.collections',
        })}`}</p>
      </div>

      <div className="flex flex-col gap-y-[50px]">
        {collections.map((collection) => (
          <Collection key={collection.name} collection={collection} />
        ))}
      </div>

      <div className="container mt-[60px] flex justify-center">
        <FNCLinkButton
          className="surface-salmon-1 min-h-[48px] w-full min-w-[318px] lg:w-auto"
          reference={shopAllCtaReference}
          icon={<MdOutlineArrowForward />}
          iconPosition="end"
        >
          {shopAllCtaText}
        </FNCLinkButton>
      </div>
    </section>
  );
}
