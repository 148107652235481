'use client';

import { MdArrowForward } from 'react-icons/md';
import Link from '~/components/furniturechoice/link';
import { TasticProps } from '~/frontastic/tastics/types';
import { Reference } from '~/types/reference';

interface PromoBannerProps {
  text: string;
  link: Reference;
}

export default function PromoBanner({ data }: TasticProps<PromoBannerProps>) {
  return (
    <div className="container" data-testid="promo-banner">
      <Link
        reference={data.link}
        className="surface-red-1 flex h-full min-h-[44px] w-full"
        data-testid="promo-banner-link"
      >
        <p className="m-auto max-w-[200px] font-bold leading-[20px] lg:max-w-full" data-testid="promo-banner-content">
          {data.text}
          <MdArrowForward size={20} className="relative ms-1 inline-block align-bottom" />
        </p>
      </Link>
    </div>
  );
}
