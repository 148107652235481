import { useParams } from 'next/navigation';
import { FNCLineItem } from 'shared/types/cart/LineItem';
import CommercetoolsImage from '~/components/furniturechoice/commercetools-image';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { useFormat } from '~/helpers/hooks/useFormat';

interface LineItemsProps {
  lineItems: FNCLineItem[];
}

export default function LineItems({ lineItems }: LineItemsProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { locale } = useParams();

  return (
    <ul>
      {lineItems.map((lineItem) => (
        <li className="flex gap-x-4" key={lineItem.lineItemId} data-testid="line-item">
          <CommercetoolsImage
            className="surface-grey-3 aspect-square w-20 rounded-sm"
            src={lineItem.variant.images?.[0]}
            sizes="80px"
          />

          <div className="flex flex-1 flex-col gap-y-8">
            <p className="font-semibold">{lineItem.name}</p>

            <div className="flex items-center justify-between">
              <p className="text-grey-4">
                {`${formatMessage({ id: 'tastics.account.order-detail-content-manager.quantity' })}: ${lineItem.count}`}
              </p>

              <p className="font-semibold">{CurrencyHelpers.formatForCurrency(lineItem.totalPrice ?? 0, locale)}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
