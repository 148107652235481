import { ImgHTMLAttributes } from 'react';
import clsx from 'clsx';
import buildImageSizes from '~/utils/build-image-sizes';

interface CommercetoolsImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  className?: string;
  src?: string;
  colSpanPercent?: number;
  colSpanPercentSm?: number;
  colSpanPercentMd?: number;
  colSpanPercentLg?: number;
  colSpanPercentXl?: number;
  colSpanPercent2Xl?: number;
}

function buildSrcSet(src: string): string {
  // Source: https://docs.commercetools.com/api/projects/products#image
  const widths: Record<string, string> = { 50: 'thumb', 150: 'small', 400: 'medium', 700: 'large', 1400: 'zoom' };
  const srcNodes = src.split('.');
  const srcType = srcNodes.pop();

  return Object.keys(widths)
    .map((width) => `${srcNodes.join('.')}-${widths[width]}.${srcType} ${width}w`)
    .join(', ');
}

/**
 * This component builds out an <img> sourcing from Commercetools with built-in support
 * for providing an image size estimate to download depending on the breakpoint. This is
 * useful so that we only download what is approximately needed. For example, a thumbnail
 * image of 150x150 doesn't need to download an image who's original size is 3000x3000.
 *
 * Column span percent parameters are provided per breakpoint. This will represent how much
 * an image will occupy in terms of width from within a .container class. For example,
 * if an image will occupy approximately 50% of the container, then you set the corresponding
 * column span percent to 0.5.
 *
 * If the image isn't in a .container class and will fill the whole edge-to-edge of the screen
 * for all devices, then you can just pass-in `sizes="100vw"` as a parameter. A usual example
 * for this scenario are Hero Banners. This is the same case for images that have the same
 * size regardless of the device size. For example, you can just pass-in `sizes="150x"` for
 * images that will be 150px on all device type.
 */
export default function CommercetoolsImage({
  className,
  src,
  loading = 'lazy',
  colSpanPercent = 1,
  colSpanPercentSm,
  colSpanPercentMd,
  colSpanPercentLg,
  colSpanPercentXl,
  colSpanPercent2Xl,
  ...props
}: CommercetoolsImageProps): JSX.Element {
  if (src) {
    const sizes = buildImageSizes({
      colSpanPercent,
      colSpanPercentSm,
      colSpanPercentMd,
      colSpanPercentLg,
      colSpanPercentXl,
      colSpanPercent2Xl,
    });

    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        className={className}
        data-testid="commercetools-image"
        // Needs to have the following order: sizes, srcSet, src
        // See: https://bugs.webkit.org/show_bug.cgi?id=190031
        sizes={sizes}
        srcSet={buildSrcSet(src)}
        src={src}
        loading={loading}
        {...props}
      />
    );
  }

  return <div className={clsx('aspect-square w-full animate-pulse bg-skeleton', className)} {...props} />;
}
