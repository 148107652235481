import { useContext } from 'react';
import { MdClose } from 'react-icons/md';
import { Variant } from 'shared/types/product';
import { LineItem, Wishlist } from 'shared/types/wishlist';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import VariantCardComplete from '~/components/furniturechoice/variant-card';
import { UseCartReturn } from '~/frontastic/hooks/useCart/types';
import { UseWishlist } from '~/frontastic/hooks/useWishlist/types';
import { MinicartContext } from '~/helpers/contexts/furniturechoice/minicart';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';

interface WishlistCardProps {
  lineItem: LineItem;
  cartManager: UseCartReturn;
  wishlistManager: UseWishlist;
}

export default function WishlistCard({ lineItem, cartManager, wishlistManager }: WishlistCardProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const { openMinicart } = useContext(MinicartContext);

  async function handleAddToBasketClick() {
    await executeWithProgress(() => cartManager.addItem(lineItem.variant as Variant, 1));
    openMinicart();
  }

  function handleRemoveFromWishlistClick() {
    executeWithProgress(() => wishlistManager.removeLineItem(wishlistManager.data as Wishlist, lineItem));
  }

  return (
    <div className="relative flex flex-col gap-y-3">
      <button
        className="absolute right-3 top-3 z-10 rounded-full bg-white p-1 text-grey-5 shadow-sm"
        data-testid="remove-button"
        onClick={() => handleRemoveFromWishlistClick()}
      >
        <MdClose className="text-26" />
        <span className="sr-only">
          {formatMessage({ id: 'tastics.wishlist.wishlist-content-manager.remove_from_wishlist' })}
        </span>
      </button>

      <VariantCardComplete
        headlineAs="h2"
        variant={lineItem.variant as Variant}
        productName={lineItem.name as string}
        imageSizing={{ colSpanPercentLg: 0.25 }}
      />

      <FNCButton className="surface-green-2" data-testid="add-to-cart-button" onClick={() => handleAddToBasketClick()}>
        {formatMessage({ id: 'tastics.wishlist.wishlist-content-manager.add_to_basket' })}
      </FNCButton>
    </div>
  );
}
