import { HTMLAttributes } from 'react';
import { ContentfulImage as ContentfulImageType } from 'shared/types/contentful/image';
import ContentfulImage from '~/components/furniturechoice/contentful-image';

export interface ContentfulHeroBannerProps extends HTMLAttributes<HTMLDivElement> {
  image: ContentfulImageType;
  text: string;
  subtext?: string;
}

export default function ContentfulHeroBanner({ image, text, subtext, ...props }: ContentfulHeroBannerProps) {
  return (
    <div
      className="relative m-auto flex h-auto w-screen max-w-screen-2xl justify-center bg-gradient-to-b from-grey-2 to-grey-5"
      {...props}
    >
      <ContentfulImage
        className="m-auto aspect-[500/375] h-[500px] w-full object-cover lg:aspect-[1536/500]"
        src={image.fields.file.url as string}
        alt={image.fields.title}
        aspectRatio={500 / 375}
        aspectRatioLg={1536 / 500}
      />
      <div className="absolute bottom-0 left-1/2 m-auto grid w-full -translate-x-1/2 gap-2 bg-gradient-to-b from-black/0 to-black/50 py-8 text-center text-white">
        <h1 className="px-4 text-32 lg:text-48">{text}</h1>
        {subtext && <p className="px-4 text-16 font-medium leading-5">{subtext}</p>}
      </div>
    </div>
  );
}
