'use client';

import { TasticProps } from '~/frontastic/tastics/types';
import MarkdownBody from './markdown-body';

export interface TextBodyProps {
  text: string;
  listType?: string;
  highlightBox?: boolean;
  textAlign?: string;
  fontSize?: string;
}

export default function TextBody({ data }: TasticProps<TextBodyProps>) {
  return (
    <div className="container">
      <MarkdownBody
        text={data.text}
        listType={data.listType}
        textAlign={data.textAlign}
        highlightBox={data.highlightBox}
        fontSize={data.fontSize}
      />
    </div>
  );
}
