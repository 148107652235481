'use client';

import CoFeImage from '~/components/furniturechoice/co-fe-image';
import SectionHeader from '~/components/furniturechoice/section-header';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';

interface ImageHighlightProps {
  title: string;
  body?: string;
  image: ImageProps;
}

export default function ImageHighlight({ data }: TasticProps<ImageHighlightProps>) {
  return (
    <section
      className="standard-section-padding container flex flex-col gap-y-10 lg:gap-y-12"
      data-testid="image-highlight"
    >
      <SectionHeader as="h2" data-testid="image-highlight-title">
        {data.title}
      </SectionHeader>

      {data.body && (
        <p
          className="mx-auto w-[330px] text-center text-16 leading-[22px] text-grey-5 lg:w-[460px]"
          data-testid="image-highlight-body"
        >
          {data.body}
        </p>
      )}

      <CoFeImage
        className="m-auto aspect-[343/320] h-full w-full rounded-md object-cover lg:aspect-[1222/667]"
        asset={data.image.media}
        aspectRatio={343 / 320}
        aspectRatioLg={1222 / 667}
        data-testid="image-highlight-image"
      />
    </section>
  );
}
