'use client';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import Link from '~/components/furniturechoice/link';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';

export interface HeroCarouselItemProps {
  desktopImage: ImageProps;
  mobileImage: ImageProps;
  imageLink: string;
}

export interface HeroCarouselProps {
  carouselImage: HeroCarouselItemProps[];
}

export default function HeroCarousel({ data }: TasticProps<HeroCarouselProps>) {
  const heroCarouselItems = data?.carouselImage || [];

  return (
    <section className="mx-auto flex max-w-screen-2xl 2xl:px-6">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        style={{
          // @ts-ignore: Object literal may only specify known properties, and ''--swiper-navigation-color'' does not exist in type 'Properties<string | number, string & {}>'.
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
      >
        {heroCarouselItems.map((item, index) => (
          <SwiperSlide key={index}>
            <Link href={item.imageLink} className="">
              <CoFeImage asset={item.mobileImage.media} className="md:hidden" aspectRatio={9 / 14} />
              <CoFeImage asset={item.desktopImage.media} className="hidden md:block" aspectRatio={21 / 9} />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
