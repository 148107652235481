import { format } from 'date-fns';
import { MdChevronRight } from 'react-icons/md';
import { Order } from 'shared/types/cart';
import Link from '~/components/furniturechoice/link';
import { useFormat } from '~/helpers/hooks/useFormat';

interface OrderListItemProps {
  order: Order;
}

export default function OrderListItem({ order }: OrderListItemProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <li
      className="surface-grey-1 flex flex-col gap-y-3 rounded-md p-4 lg:flex-row lg:justify-between"
      data-testid="order-list-item"
    >
      <p className="flex flex-col gap-y-1">
        <span className="font-bold">
          {`${formatMessage({ id: 'tastics.account.orders-content-manager.order_number' })}:`}
        </span>

        <span className="text-grey-4">{order.orderNumber}</span>
      </p>

      <p className="flex flex-col gap-y-1">
        <span className="font-bold">
          {`${formatMessage({ id: 'tastics.account.orders-content-manager.order_date' })}:`}
        </span>

        <span className="text-grey-4">{format(order.createdAt as Date, 'dd/MM/yyyy')}</span>
      </p>

      <Link className="flex items-center gap-x-0.5 text-grey-4 lg:self-start" href={`/account/orders/${order.orderId}`}>
        <span className="underline">
          {formatMessage({ id: 'tastics.account.orders-content-manager.view_order_details' })}
        </span>

        <MdChevronRight className="text-14" />
      </Link>
    </li>
  );
}
