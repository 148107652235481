'use client';

import { useState } from 'react';
import { RangeFacet } from 'shared/types/result';
import { FNCCategoryFacet } from 'shared/types/result/Facet';
import FilterRangeSlider from './filter-range-slider';
import Accordion from '../filter-accordion';

interface FilterFacetProp {
  facet: FNCCategoryFacet;
  title: string;
  onRangeChange: (values: number[], initialMinValue: number, intialMaxValue: number) => void;
}

export interface FilterItem {
  label: string;
  count: number;
  selected: boolean;
  url: string;
}

export default function FilterFacetRange({ facet, title, onRangeChange }: FilterFacetProp): JSX.Element {
  const isPriceFacet = facet.identifier === 'variants.price';
  const rangeFacet = facet as RangeFacet;
  const maxAmount = isPriceFacet ? toFullAmount(rangeFacet.max ?? 0) : rangeFacet.max ?? 0;
  const minAmount = isPriceFacet ? toFullAmount(rangeFacet.min ?? 0) : rangeFacet.min ?? 0;
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(facet.selected ?? false);

  function toFullAmount(value: number) {
    const stringVal = value.toString();

    if (stringVal.length > 3) {
      return parseInt(stringVal.slice(0, -2));
    }

    return 0;
  }

  function handleRangeChange(values: number[], initialMinValue: number, initialMaxValue: number) {
    setIsAccordionOpen(true);
    onRangeChange(values, initialMinValue, initialMaxValue);
  }

  const el = (
    <div>
      <div className="mx-6 mt-6">
        <FilterRangeSlider
          min={minAmount}
          max={maxAmount}
          onSliderChange={handleRangeChange}
          minSelected={rangeFacet.minSelected}
          maxSelected={rangeFacet.maxSelected}
        />
      </div>
    </div>
  );

  if (minAmount !== maxAmount) {
    return (
      <div>
        <Accordion
          contents={[
            {
              headingTag: 'h5',
              headingText: title,
              panel: el,
              isExpanded: isAccordionOpen,
            },
          ]}
        />
      </div>
    );
  } else {
    return <></>;
  }
}
