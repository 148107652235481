'use client';

import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { useFormat } from '~/helpers/hooks/useFormat';

export interface CategoryHeaderProps extends HTMLAttributes<HTMLDivElement> {
  query: string;
  resultCount: number;
}

export default function SearchHeader({ className, query, resultCount, ...props }: CategoryHeaderProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <header className={clsx('container m-auto py-5 text-center', className)} {...props}>
      <h1 className="text-32 capitalize leading-[36px] text-grey-6 lg:text-48 lg:leading-[58px]">
        {formatMessage({ id: 'tastics.search.search-content-manager.search-header.header' })}
      </h1>
      <span className="mx-auto mb-4 mt-2 block h-px w-[42px] bg-salmon-4 lg:mb-6 lg:h-0.5 lg:w-[60px]"></span>
      <p
        className="text-12 leading-4 text-grey-5 lg:text-16 lg:leading-5 [&_b]:font-medium [&_strong]:font-medium"
        dangerouslySetInnerHTML={{
          __html: formatMessage({
            id: 'tastics.search.search-content-manager.search-header.total_result',
            values: {
              query,
              resultCount,
            },
          }),
        }}
      />
    </header>
  );
}
