'use client';

import { BsBoxSeam } from 'react-icons/bs';
import { Account } from 'shared/types/account';
import { FNCOrder } from 'shared/types/furniturechoice/order';
import AccountContainer from '~/components/furniturechoice/account-container';
import { useAccount } from '~/frontastic/hooks';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import { DataSourceV2 } from '~/types/datasource';
import Order from './order';

interface OrderDetailContentManagerProps {
  accountWithOrder: DataSourceV2<{ account: Account; order: FNCOrder }>;
}

export default function OrderDetailContentManager({
  data: { accountWithOrder },
}: TasticProps<OrderDetailContentManagerProps>) {
  const { account, order } = accountWithOrder.dataSource;
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const accountManager = useAccount();
  const accountData = accountManager.account || account;

  return (
    <section className="standard-section-padding">
      <AccountContainer
        account={accountData}
        contentHeadline={formatMessage({ id: 'tastics.account.order-detail-content-manager.my_orders' })}
        contentHeadlineIcon={<BsBoxSeam />}
      >
        <Order order={order} />
      </AccountContainer>
    </section>
  );
}
