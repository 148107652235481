'use client';

import { MdPassword } from 'react-icons/md';
import { Account } from 'shared/types/account';
import AccountContainer from '~/components/furniturechoice/account-container';
import { useAccount } from '~/frontastic/hooks';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import { DataSourceV2 } from '~/types/datasource';
import Form from './form';

interface DetailsContentManagerProps {
  account: DataSourceV2<Account>;
}

export default function ChangePasswordContentManager({ data: { account } }: TasticProps<DetailsContentManagerProps>) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const accountManager = useAccount();
  const accountData = accountManager.account || account.dataSource;

  return (
    <section className="standard-section-padding">
      <AccountContainer
        account={accountData}
        contentHeadline={formatMessage({ id: 'tastics.account.change-password-content-manager.change_password' })}
        contentHeadlineIcon={<MdPassword />}
      >
        <div className="flex flex-col gap-y-10">
          <Form accountManager={accountManager} />
        </div>
      </AccountContainer>
    </section>
  );
}
