'use client';

import Slider from './slider';
import { Collection as CollectionType } from '..';

interface CollectionProps {
  collection: CollectionType;
}

export default function Collection({ collection }: CollectionProps) {
  return (
    <section className="flex flex-col gap-y-3">
      <header className="container flex flex-col gap-y-3">
        <h2 className="font-playfair-display-sc text-32 leading-6 text-black">{collection.name}</h2>
        <p className="text-14 leading-6 text-grey-4">{collection.supportingText}</p>
      </header>

      <Slider image={collection.image} reference={collection.reference} variations={collection.variations} />
    </section>
  );
}
