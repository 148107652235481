'use client';

import { MdArrowForward } from 'react-icons/md';
import FNCLinkButton from '~/components/furniturechoice/fnc/fnc-link-button';
import TrustpilotWidget from '~/components/furniturechoice/trustpilot-widget';
import { TasticProps } from '~/frontastic/tastics/types';
import { LinkReference } from '~/types/reference';

export interface TrustpilotTasticProps {
  templateId: string;
  name: string;
  subtitle?: string;
  showButton: boolean;
  buttonText?: string;
  height: string; // px
  width: string; //px or percentage
  className?: string;
  tags?: string; // data-tags="Dining" data-tags="Living" data-tags="Bedroom"
  stars?: number; // data-stars="4,5"
  language?: string; // data-review-languages="en"
  schemaType?: string; // data-schema-type="Organization"
}

export default function TrustpilotTastic({ data }: TasticProps<TrustpilotTasticProps>) {
  const linkRef: LinkReference = {
    type: 'link',
    link: process.env.NEXT_PUBLIC_TRUSTPILOT_REVIEW_URL + '?utm_medium=trustbox&amp;utm_source=Mini',
    target: '_blank',
    openInNewWindow: true,
  };
  return (
    <div className="container m-auto text-center">
      <h2 className="font-playfair-display text-32 leading-[42px] text-grey-6 lg:text-48 lg:leading-[58px]">
        {data.name}
      </h2>
      <span className="m-auto mb-4 mt-2 block h-0.5 w-[42px] bg-salmon-4 lg:mb-6 lg:w-[60px]"></span>
      {data.subtitle && <h1 className="font-sans text-20 font-normal text-grey-4 lg:text-24">{data.subtitle}</h1>}
      <TrustpilotWidget
        templateId={data.templateId}
        styleHeight={data.height}
        styleWidth={data.width}
        className={data.className}
        tags={data.tags}
        stars={data.stars}
        language={data.language}
        schemaType={data.schemaType}
      />
      {data.showButton && data.buttonText && (
        <FNCLinkButton
          className="surface-green-4 m-auto my-16 max-w-lg md:max-w-[20rem]"
          icon={<MdArrowForward className="inline text-16" />}
          iconPosition="end"
          reference={linkRef}
        >
          {data.buttonText}
        </FNCLinkButton>
      )}
    </div>
  );
}
