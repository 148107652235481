'use client';

import clsx from 'clsx';
import { MdArrowForward } from 'react-icons/md';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import FNCLinkButton from '~/components/furniturechoice/fnc/fnc-link-button';
import Link from '~/components/furniturechoice/link';
import SectionHeader from '~/components/furniturechoice/section-header';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';
import { Reference } from '~/types/reference';
import ArticleHighlightTile from './article-tile';

export interface Article {
  title: string;
  link: Reference;
  image: ImageProps;
}

interface ArticleHighlightsProps {
  headerText: string;
  articles: Article[];
  btnText: string;
  btnLink: Reference;
}

export default function ArticleHighlights({ data }: TasticProps<ArticleHighlightsProps>) {
  const firstArticle = data.articles[0];

  return (
    <section className="standard-section-padding lg:container" data-testid="article-highlights">
      <SectionHeader as="h2" className="mb-4">
        {data.headerText}
      </SectionHeader>

      <div className="flex aspect-[375/620] rounded-md lg:aspect-[1200/658]" data-testid="article">
        <Link reference={firstArticle.link} className="relative">
          <CoFeImage
            className="m-auto h-full w-full object-cover brightness-75"
            asset={firstArticle.image.media}
            colSpanPercent={1.0}
            aspectRatio={375 / 620}
            aspectRatioLg={1200 / 658}
          />
          <p
            className={clsx(
              'absolute inset-x-4 bottom-12',
              'font-playfair-display text-32 leading-[29px] text-white lg:text-center',
              'lg:left-1/2 lg:-translate-x-1/2 lg:text-48 lg:leading-[46px]',
            )}
          >
            {firstArticle.title}
            <MdArrowForward size={40} className="relative ms-1 inline-block align-bottom" />
          </p>
        </Link>
      </div>

      <div className="grid grid-rows-4 lg:mt-8 lg:grid-cols-4 lg:grid-rows-none lg:gap-x-2">
        {data.articles.map((article, index) => {
          if (index > 0) {
            return <ArticleHighlightTile title={article.title} image={article.image} link={article.link} key={index} />;
          }
        })}
      </div>

      <FNCLinkButton
        className="surface-salmon-1 m-auto my-8 flex h-12 w-[300px] drop-shadow-xl"
        icon={<MdArrowForward className="inline text-16 lg:hidden" />}
        iconPosition="end"
        reference={data.btnLink}
      >
        {data.btnText}
      </FNCLinkButton>
    </section>
  );
}
