'use client';

import React from 'react';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import Link from '~/components/furniturechoice/link';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import { Reference } from '~/types/reference';

export interface FootnoteLink {
  label: string;
  reference: Reference;
  type: string;
}

export interface FooterFootnotesProps {
  footnoteLinks: Array<FootnoteLink>;
  disclaimer: string;
  logo: ImageProps;
}

function processLink(link: FootnoteLink): JSX.Element {
  if (link.type == 'hyperlink') {
    return (
      <Link
        reference={link.reference}
        //ga4_event_name="navigation_footer"
        //ga4_event_param_1_name="element"
        //ga4_event_param_1_value={link.label}
      >
        {link.label}
      </Link>
    );
  } else {
    return (
      <button
        id="manage_cookies"
        //ga4_event_name="navigation_footer"
        //ga4_event_param_1_name="element"
        //ga4_event_param_1_value={link.label}
      >
        {link.label}
      </button>
    );
  }
}

/**
 * Formats and displays footer footnotes containing a logo, links set up in co-fe,
 * hardcoded company and registration info and additional disclaimer info from co-fe.
 *
 * There are currently 2 types of links, a standard reference link and a special button
 * to call a dialog to manage cookies
 */
export default function FooterFootnotes({ data }: TasticProps<FooterFootnotesProps>): JSX.Element {
  const year = new Date().getFullYear();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  return (
    <div className="container mb-10 md:mb-20">
      <div className="gap-x-6 border-t-2 pt-2 text-12 text-grey-4 md:flex md:pt-3">
        <a
          className="block w-40 min-w-[160px] md:w-44 md:min-w-[176px]"
          href="/"
          //ga4_event_name="banner_click"
          //ga4_event_param_1_name="element"
          //ga4_event_param_1_value="Footer Logo"
        >
          <CoFeImage className="aspect-[160/50] md:aspect-[176/55]" asset={data.logo.media} sizes="176px" />
        </a>

        <div className="mt-4 md:mt-0">
          <ul className="flex flex-wrap gap-x-1 font-bold">
            {data.footnoteLinks.map((link, index) => (
              <li key={index} className="text-12 leading-4 text-grey-6 after:content-['_|_'] last:after:content-['']">
                {processLink(link)}{' '}
              </li>
            ))}
          </ul>
          <div className="mt-2">
            &copy; {year} {formatMessage({ id: 'tastics.core.site-footer.company_name' })}{' '}
            {formatMessage({ id: 'tastics.core.site-footer.all_rights_reserved' })} |{' '}
            {formatMessage({ id: 'tastics.core.site-footer.company_no' })} |{' '}
            {formatMessage({ id: 'tastics.core.site-footer.vat_no' })}
          </div>
          <div className="mt-2">{data.disclaimer}</div>
        </div>
      </div>
    </div>
  );
}
