import { FilterEntry } from '../types/filter-entry';

export default function buildFilterObject(filter: string[]) {
  const filterArr: FilterEntry[] = [];

  filter.forEach((item, index) => {
    if (index % 2 === 0 && filter[index + 1]) {
      filterArr.push({ key: item, values: filter[index + 1].split(',') });
    }
  });

  return filterArr;
}
