import { Fragment, HTMLAttributes, useEffect, useId, useState } from 'react';
import Heading from './heading';
import Panel from './panel';

interface AccordionContent {
  headingTag: string;
  headingText: string;
  panel: JSX.Element | string;
  isExpanded: boolean;
}

interface FilterAccordionProps extends HTMLAttributes<HTMLDivElement> {
  contents: AccordionContent[];
}

export default function FilterAccordion({ contents, ...props }: FilterAccordionProps): JSX.Element {
  const [accordionStatuses, setAccordionStatuses] = useState<boolean[]>(contents.map((content) => content.isExpanded));
  const headingId = useId();
  const panelId = useId();

  useEffect(() => {
    setAccordionStatuses(contents.map((content) => content.isExpanded));
  }, [contents]);

  function handleHeadingClick(indexToUpdate: number): void {
    const newAccordionStatuses = accordionStatuses.map((status, index) => {
      if (index === indexToUpdate) {
        return !status;
      }

      return status;
    });

    setAccordionStatuses(newAccordionStatuses);
  }

  const contentEls = contents.map((content, index) => {
    const headingIndexId = `${headingId}${index}`;
    const panelIndexId = `${panelId}${index}`;

    if (content.panel) {
      return (
        <Fragment key={content.headingText}>
          <Heading
            headingId={headingIndexId}
            headingTag={content.headingTag}
            headingText={content.headingText}
            panelId={panelIndexId}
            isExpanded={accordionStatuses[index]}
            onClick={() => handleHeadingClick(index)}
          />
          <Panel
            panelId={panelIndexId}
            panelContent={content.panel}
            headingId={headingIndexId}
            isExpanded={accordionStatuses[index]}
          />
        </Fragment>
      );
    } else {
      return null;
    }
  });

  return <div {...props}>{contentEls}</div>;
}
