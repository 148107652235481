import { FilterEntry } from '../types/filter-entry';

export function replaceFilterValue(filterEntries: FilterEntry[], key: string, newValue: string): FilterEntry[] {
  const updatedEntries = filterEntries.map((entry) => ({ ...entry }));

  // Find the index of the entry with the given key
  const index = updatedEntries.findIndex((entry) => entry.key === key);

  if (index !== -1) {
    // Key exists, replace the values array with the new value
    updatedEntries[index].values = [newValue];
  } else {
    // Key does not exist, add a new entry
    updatedEntries.push({ key, values: [newValue] });
  }

  return updatedEntries;
}
