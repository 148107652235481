'use client';

import SnippetProps from 'shared/types/contentful/module/Snippet';
import Snippet from '~/components/furniturechoice/rich-text/contentful/module/snippet';
import { TasticProps } from '~/frontastic/tastics/types';
import { DataSourceV2 } from '~/types/datasource';

interface ContentfulSnippetProps {
  data: DataSourceV2<SnippetProps>;
}

export default function ContentfulSnippet({ data }: TasticProps<ContentfulSnippetProps>) {
  if (!data.data.dataSource) {
    return <></>;
  } else {
    return (
      <div className="container">
        <Snippet data={data.data.dataSource} />
      </div>
    );
  }
}
