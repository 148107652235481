import { FilterEntry } from '../types/filter-entry';

export function removeFilterValue(filterEntries: FilterEntry[], key: string, value: string): FilterEntry[] {
  const updatedEntries = filterEntries.map((entry) => ({ ...entry }));

  // Find the index of the entry with the given key
  const index = updatedEntries.findIndex((entry) => entry.key === key);

  if (index !== -1) {
    // Key exists, remove the value if it exists
    const existingValues = [...updatedEntries[index].values];
    const valueIndex = existingValues.indexOf(value);

    if (valueIndex !== -1) {
      existingValues.splice(valueIndex, 1);
      // Update the entry with the new values array
      updatedEntries[index].values = existingValues;
      // Remove the key entry if no values remain
      if (existingValues.length === 0) {
        updatedEntries.splice(index, 1);
      }
    }
  }

  return updatedEntries;
}
