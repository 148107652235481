'use client';

import clsx from 'clsx';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';

interface HeroBannerProps {
  image: ImageProps;
  text: string;
  brightness: string;
}

export default function HeroBanner({ data }: TasticProps<HeroBannerProps>) {
  return (
    <div className="relative flex h-auto w-screen justify-center" data-testid="hero-banner">
      <CoFeImage
        className={clsx(data.brightness, 'm-auto aspect-[9/16] max-h-[675px] lg:aspect-video lg:object-cover')}
        asset={data.image.media}
        colSpanPercentLg={1.0}
        aspectRatio={9 / 16}
        aspectRatioLg={16 / 9}
        data-testid="hero-banner-image"
      />
      <h1
        className="absolute left-1/2 top-1/2 m-auto -translate-x-1/2 -translate-y-1/2 text-center text-48 leading-10 text-white lg:text-96 lg:leading-[81px]"
        data-testid="hero-banner-text"
      >
        {data.text}
      </h1>
    </div>
  );
}
