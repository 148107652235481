import { FilterEntry } from '../types/filter-entry';

export function buildFilterString(filters: FilterEntry[]): string {
  const convertedObjString = filters
    .filter(({ values }) => values.length > 0)
    .map(({ key, values }) => `${key}/${values.join(',')}`)
    .join('/');

  const filterString = convertedObjString.length > 0 ? `/f/${convertedObjString}/` : '';

  return filterString;
}
