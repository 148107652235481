'use client';

import clsx from 'clsx';
import { MdCheck, MdFormatQuote } from 'react-icons/md';
import { Components } from 'react-markdown';
import RichTextMarkdown from '~/components/furniturechoice/rich-text/markdown';

export interface MarkdownBodyProps {
  text: string;
  listType?: string;
  textAlign?: string;
  highlightBox?: boolean;
  fontSize?: string;
}

export default function MarkdownBody({ text, listType, textAlign, highlightBox, fontSize }: MarkdownBodyProps) {
  if (!listType) listType = 'dash';

  const markdownComponents: Components = {
    p({ children }) {
      return <p className="leading-5">{children}</p>;
    },
    ul({ children }) {
      if (listType == 'check') {
        return <ul className="pl-4">{children}</ul>;
      } else {
        return <ul className="pl-4 [&_li]:list-['—'] [&_li]:marker:text-salmon-4">{children}</ul>;
      }
    },
    ol({ children }) {
      return (
        <ol className="relative mt-2 grid gap-y-2 pl-4 [counter-reset:section] [&_li]:marker:[content:counters(section,'.')'.']">
          {children}
        </ol>
      );
    },
    li({ children }) {
      if (listType == 'check') {
        return (
          <li className="mb-2">
            <span className="float-left -ml-6 h-5">
              <MdCheck className="fill-green-1 text-22" />
            </span>
            {children}
          </li>
        );
      } else {
        return <li className="mb-2 ps-1.5 [counter-increment:section] [&_ol]:pl-8">{children}</li>;
      }
    },
    h1({ children }) {
      return <h2 className="mt-4 text-20 font-bold [&+hr]:border-grey-6">{children}</h2>;
    },
    h2({ children }) {
      return <h3 className="mt-4 text-18 font-bold text-grey-5">{children}</h3>;
    },
    h3({ children }) {
      return <h4 className="mt-4 text-16 font-bold text-grey-5">{children}</h4>;
    },
    hr() {
      return <hr className="border-grey-3" />;
    },
    blockquote({ children }) {
      return (
        <blockquote className="text-center text-salmon-4 after:m-auto after:my-2 after:block after:h-1 after:w-12 after:bg-salmon-1 after:content-[''] [&+p]:text-center [&_p]:inline">
          <MdFormatQuote className="inline rotate-180 align-baseline text-22" />
          {children}
          <MdFormatQuote className="inline align-baseline text-22" />
        </blockquote>
      );
    },
  };

  const fontClass = fontSize ? 'text-' + fontSize : '';

  const className = clsx(fontClass, {
    'p-4 bg-grey-1': highlightBox,
    'text-center': textAlign && textAlign == 'center',
  });

  return <RichTextMarkdown className={className} data={text} components={markdownComponents} />;
}
