'use client';

import clsx from 'clsx';
import { MdTune } from 'react-icons/md';
import { useFormat } from '~/helpers/hooks/useFormat';

interface ProductCardManagerProps {
  isLoading: boolean;
  className?: string;
  onFilterClick: () => void;
  activeSortOrder: string;
  onSortChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export default function ProductCardManager({
  isLoading,
  className,
  onFilterClick,
  activeSortOrder,
  onSortChange,
}: ProductCardManagerProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className={clsx('mb-5 flex rounded-md border border-grey-3 [&>*]:basis-full [&>*]:p-3', className)}>
      <button className="flex items-center border-r border-grey-3 text-14 leading-5" onClick={() => onFilterClick()}>
        <MdTune size={15} className="mr-1.5" />
        <span>{formatMessage({ id: 'tastics.category.collection.product-card-manager.filter' })}</span>
      </button>
      <div className="text-14 leading-5">
        <label className="hidden" htmlFor="sort_by">
          {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort' })}
        </label>

        <select
          id="sort_by"
          name="sort_by"
          className="w-full"
          value={activeSortOrder}
          onChange={onSortChange}
          disabled={isLoading}
        >
          <option value="recommended">
            {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort_recommended' })}
          </option>
          <option value="topseller">
            {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort_topseller' })}
          </option>
          <option value="price-highest">
            {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort_price_high' })}
          </option>
          <option value="price-lowest">
            {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort_price_low' })}
          </option>
          <option value="newest">
            {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort_newest' })}
          </option>
        </select>
      </div>
    </div>
  );
}
