import { LineItem } from 'shared/types/wishlist';
import Link from '~/components/furniturechoice/link';
import { UseCartReturn } from '~/frontastic/hooks/useCart/types';
import { UseWishlist } from '~/frontastic/hooks/useWishlist/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import WishlistCard from './wishlist-card';

interface WishlistGridProps {
  lineItems: LineItem[];
  cartManager: UseCartReturn;
  wishlistManager: UseWishlist;
}

export default function WishlistGrid({ lineItems, cartManager, wishlistManager }: WishlistGridProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4">
      {lineItems.map((lineItem) => (
        <WishlistCard
          key={lineItem.lineItemId}
          lineItem={lineItem}
          cartManager={cartManager}
          wishlistManager={wishlistManager}
        />
      ))}

      {lineItems.length === 0 && (
        <div className="col-span-full flex flex-col justify-center gap-y-1" data-testid="empty-state">
          <p className="text-center text-grey-5">
            {formatMessage({ id: 'tastics.wishlist.wishlist-content-manager.add_an_item_to_your_wishlist' })}
          </p>

          <Link className="text-center text-salmon-1">
            {formatMessage({ id: 'tastics.wishlist.wishlist-content-manager.continue_shopping' })}
          </Link>
        </div>
      )}
    </div>
  );
}
