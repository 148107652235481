import { ContentfulCategoryBadges } from 'shared/types/product/Category';
import Link from '~/components/furniturechoice/link';

interface CollectionBadgeProp {
  collections: ContentfulCategoryBadges[];
}

export default function CollectionBadges({ collections }: CollectionBadgeProp): JSX.Element {
  const links = collections.map((c, i) => {
    const { label, url } = c.fields;

    return (
      <Link
        key={i}
        href={url}
        className="surface-salmon-1 shrink-0 rounded-md p-2.5 text-12 leading-3.5 first:ml-4 last:mr-4 lg:first:ml-0 lg:last:mr-0"
      >
        {label}
      </Link>
    );
  });

  return (
    <div className="-mx-4 my-6 flex gap-2.5 overflow-x-auto lg:mx-0 lg:justify-center [&::-webkit-scrollbar]:hidden">
      {links}
    </div>
  );
}
