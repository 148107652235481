'use client';

import { useContext, useEffect, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { SearchResult } from 'shared/types/result';
import { TasticProps } from '~/frontastic/tastics/types';
import { FilterDrawerContext } from '~/helpers/contexts/furniturechoice/filter-drawer';
import ProgressIndicatorContext from '~/helpers/contexts/furniturechoice/progress-indicator/context';
import usePathFilter from '~/helpers/hooks/furniturechoice/use-path-filter';
import { DataSourceV2 } from '~/types/datasource';
import MainProductContent from './main-content';
import ProductCardManager from './product-card-manager';
import SearchHeader from './search-header';
import { addFilterValue } from './utils/add-filter-value';
import buildFilterObject from './utils/build-filter-object';
import { buildFilterString } from './utils/build-filter-string';
import { removeFilterValue } from './utils/remove-filter-value';
import { replaceFilterValue } from './utils/replace-filter-value';
import { FilterEntry } from './utils/types/filter-entry';

interface SearchContentManagerProps {
  search: DataSourceV2<SearchResult>;
}

export default function SearchContentManager({ data: { search } }: TasticProps<SearchContentManagerProps>) {
  const router = useRouter();
  const pathFilters = usePathFilter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const sortParam = searchParams.get('sortBy') || 'recommended';
  const { navigateWithProgress, isProgressIndicatorVisible } = useContext(ProgressIndicatorContext);
  const { closeFilterDrawer, openFilterDrawer, isFilterDrawerVisible } = useContext(FilterDrawerContext);

  const [activeSortOrder, setActiveSortOrderParam] = useState<string>(sortParam);
  const [filterObject, setFilterObject] = useState<FilterEntry[]>(buildFilterObject(pathFilters));

  // Drawer states
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    setActiveSortOrderParam(sortParam);
  }, [sortParam]);

  useEffect(() => {
    if (pathFilters.length > 0) {
      const initialFilterObject = buildFilterObject(pathFilters);
      setFilterObject(initialFilterObject);
    }
  }, [pathFilters]);

  function handleSortChange(event: React.ChangeEvent<HTMLSelectElement>) {
    navigateWithProgress(async () => {
      const params = new URLSearchParams(searchParams.toString());
      params.set('sortBy', event.target.value);
      setActiveSortOrderParam(event.target.value);

      router.push(`${pathname}?${params.toString()}`);
    });
  }

  function handleFilterChange(name: string, value: string, action: 'add' | 'replace' | 'remove') {
    navigateWithProgress(async () => {
      let updatedValue;
      const decodedValue = encodeURIComponent(value);

      if (action === 'add') {
        updatedValue = addFilterValue(filterObject, name, decodedValue);
      } else if (action === 'replace') {
        updatedValue = replaceFilterValue(filterObject, name, decodedValue);
      } else {
        updatedValue = removeFilterValue(filterObject, name, decodedValue);
      }

      setFilterObject(updatedValue);

      const newFilterString = buildFilterString(updatedValue);
      const newFilterPath = pathname.includes('/f/')
        ? pathname.replace(/\/f\/.*/, newFilterString)
        : `${pathname}${newFilterString}`;

      router.push(newFilterPath, { scroll: false });
    });
  }

  return (
    <section className="container">
      <SearchHeader query={search.dataSource.result.searchTerm || ''} resultCount={search.dataSource.result.total} />
      <ProductCardManager
        isLoading={isProgressIndicatorVisible}
        className="lg:hidden"
        onFilterClick={() => setMobileFilterOpen(!mobileFilterOpen)}
        activeSortOrder={activeSortOrder}
        onSortChange={handleSortChange}
      />
      <MainProductContent
        isLoading={isProgressIndicatorVisible}
        products={search.dataSource.result}
        isMobileFilterOpen={mobileFilterOpen}
        isDesktopFilterOpen={isFilterDrawerVisible}
        setMobileFilterOpen={setMobileFilterOpen}
        setDesktopFilterOpen={openFilterDrawer}
        setDesktopFilterClose={closeFilterDrawer}
        handleFilterChange={handleFilterChange}
        activeSortOrder={activeSortOrder}
        handleSortChange={handleSortChange}
      />
    </section>
  );
}
