'use client';

import ContentfulInspiration from 'shared/types/contentful/page/Inspiration';
import NotFound from '~/components/commercetools-ui/organisms/not-found';
import ContentfulHeroBanner from '~/components/furniturechoice/contentful-hero-banner';
import InspirationArticle from '~/components/furniturechoice/rich-text/contentful/page/inspiration-article';
import { TasticProps } from '~/frontastic/tastics/types';
import { DataSourceV2 } from '~/types/datasource';

interface InspirationArticleManagerProps {
  data: DataSourceV2<ContentfulInspiration>;
}

export default function InspirationArticleManager({ data }: TasticProps<InspirationArticleManagerProps>) {
  if (!data.data || !data.data.dataSource) {
    return <NotFound />;
  } else {
    return (
      <>
        {data.data.dataSource.heroBanner && (
          <ContentfulHeroBanner
            image={data.data.dataSource.heroBanner}
            text={data.data.dataSource.name}
            subtext={data.data.dataSource.description}
          />
        )}
        <div className="container m-auto mt-8">
          <InspirationArticle data={data.data.dataSource} />
        </div>
      </>
    );
  }
}
