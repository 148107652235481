import { useState } from 'react';

interface ExpandableTextProps {
  readMore?: string;
  readLess?: string;
  children?: string;
  ellipsis?: boolean;
  buttonClass?: string;
  breakAt?: number;
  minBreak?: number;
}

/**
 * Expands text based on 2 conditions. It can expand if the text contains [summary] or if
 * it has breakAt set and meets the mininum length condition which is minBreak. If minBreak is not
 * set, then there is no minimum length condition.
 *
 * ellipsis boolean shows 3 dots before read more
 * readMore string text for read more button
 * readLess string text for read less button
 * buttonClass string any extra styling for the button
 */
export default function ExpandableTextArea({
  children,
  buttonClass = 'text-salmon-4',
  readMore = 'Show more',
  readLess = 'Show less',
  ellipsis = false,
  breakAt = 0,
  minBreak = 0,
}: ExpandableTextProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const fullText = children || '';
  const hasIndex = fullText.indexOf('[summary]') === -1 ? false : true;
  const length = fullText.length;
  const startIndex = hasIndex ? fullText.indexOf('[summary]') + '[summary]'.length : 0;
  const endIndex = hasIndex ? fullText.indexOf('[/summary]') : breakAt && length >= minBreak ? breakAt : 0;
  const expandedText = fullText.replace(/\[\/?summary\]/g, '') + (ellipsis ? ' ' : '');
  const extractedSummary = fullText.substring(startIndex, endIndex).trim() + (ellipsis ? '... ' : '');
  const expandable = hasIndex || (breakAt && length >= minBreak);

  /* Set the state of text to be not expanded by default.*/
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  /* Preprocess text to remove [summary] and [/summary] tags if text is expanded */
  const processedText = expandable && !isExpanded ? extractedSummary : expandedText;

  return (
    <div>
      <span>{processedText}</span>
      {expandable && (
        <button className={buttonClass} onClick={toggleText}>
          {isExpanded ? readLess : readMore}
        </button>
      )}
    </div>
  );
}
