import { MdTune, MdArrowForwardIos, MdArrowBackIos } from 'react-icons/md';
import { useFormat } from '~/helpers/hooks/useFormat';

interface GridHeader {
  productTotal: number;
  activeSortOrder: string;
  onSortChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  isFilterDrawerOpen: boolean;
  onFilterLabelClick: () => void;
}

export default function GridHeader({
  productTotal,
  activeSortOrder,
  onSortChange,
  isFilterDrawerOpen,
  onFilterLabelClick,
}: GridHeader): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className="flex border-b border-b-grey-3 pb-3">
      <div className="flex w-full items-center">
        <button
          data-testid="grid-filterlabel"
          className="mr-auto hidden cursor-pointer items-center font-sans text-16 font-medium leading-5 lg:flex"
          onClick={() => onFilterLabelClick()}
        >
          <MdTune size={18} className="mr-1.5" />
          {formatMessage({ id: 'tastics.category.collection.product-card-grid.filter_products' })}
          {isFilterDrawerOpen ? (
            <MdArrowBackIos size={12} className="ml-12" />
          ) : (
            <MdArrowForwardIos size={12} className="ml-12" />
          )}
        </button>
        <div className="ml-auto mr-5 text-12 font-light leading-4.5 lg:text-14">
          <span>
            {formatMessage({
              id: 'tastics.category.collection.product-card-grid.total_count',
              values: { totalCount: productTotal },
            })}
          </span>
        </div>
        <div className="hidden whitespace-nowrap text-14 leading-5 lg:flex">
          <label data-testid="grid-sortlabel" className="ml-5 mr-2 hidden lg:block" htmlFor="sort_by">
            {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort' })}
          </label>

          <select
            data-testid="grid-sortby"
            className="w-full font-bold"
            onChange={onSortChange}
            value={activeSortOrder}
            name="sort_by"
            id="sort_by"
          >
            <option value="recommended">
              {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort_recommended' })}
            </option>
            <option value="topseller">
              {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort_topseller' })}
            </option>
            <option value="price-highest">
              {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort_price_high' })}
            </option>
            <option value="price-lowest">
              {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort_price_low' })}
            </option>
            <option value="newest">
              {formatMessage({ id: 'tastics.category.collection.product-card-manager.sort_newest' })}
            </option>
          </select>
        </div>
      </div>
    </div>
  );
}
