'use client';

import { useRef, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { V12NotificationHandlerPayload } from 'shared/types/payment/v12';
import { TasticProps } from '~/frontastic/tastics/types';
import { DataSourceV2 } from '~/types/datasource';

interface V12NotificationHandlerProps {
  data: DataSourceV2<V12NotificationHandlerPayload>;
}

export default async function V12NotificationHandler({ searchParams }: TasticProps<V12NotificationHandlerProps>) {
  const initialized = useRef(false);
  const router = useRouter();

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      // Referred, Declined, Approved, AMended, Signed, Funded, Cancelled
      if (searchParams.Status) {
        // failed
        if (searchParams.Status == 'D' || searchParams.Status == 'C') {
          router.push(
            '/orderfailure/' +
              (searchParams.SSR && searchParams.SSR.length > 1 ? searchParams.SSR : searchParams.SR) +
              '/?holdreason=declined&method=v12',
          );
        }
        // referred
        else if (searchParams.Status == 'R') {
          router.push(
            '/orderacknowledge/' +
              (searchParams.SSR && searchParams.SSR.length > 1 ? searchParams.SSR : searchParams.SR) +
              '/?holdreason=referred&ref=' +
              searchParams.REF,
          );
        }
        // success
        else if (searchParams.Status == 'A' || searchParams.Status == 'S') {
          router.push(
            '/orderacknowledge/' +
              (searchParams.SSR && searchParams.SSR.length > 1 ? searchParams.SSR : searchParams.SR) +
              '/?ref=' +
              searchParams.REF,
          );
        } else {
          // how did it get here?
          router.push('/checkout/payment/?holdreason=unknown');
        }
      } else {
        // invalid request
        router.push('/');
      }
    }
  });

  return (
    <div>
      <div className='fixed inset-x-0 top-0 z-1200 h-1 overflow-hidden bg-salmon-3 after:block after:h-full after:w-full after:animate-[progress-indicator_2s_infinite] after:bg-salmon-1 after:content-[""]' />
    </div>
  );
}
