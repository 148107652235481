'use client';

import { Wishlist } from 'shared/types/wishlist';
import SectionHeader from '~/components/furniturechoice/section-header';
import { useCart, useWishlist } from '~/frontastic/hooks';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import { DataSourceV2 } from '~/types/datasource';
import WishlistGrid from './wishlist-grid';

interface WishlistContentManager {
  wishlist: DataSourceV2<Wishlist>;
}

export default function WishlistContentManager({ data: { wishlist } }: TasticProps<WishlistContentManager>) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const cartManager = useCart();
  const wishlistManager = useWishlist();
  const wishlistData = wishlistManager.data || wishlist.dataSource;

  return (
    <section data-testid="wishlist" className="standard-section-padding container">
      <SectionHeader as="h1" className="mb-6 lg:mb-8" data-testid="wishlist-header">
        {formatMessage({ id: 'tastics.wishlist.wishlist-content-manager.header' })}
      </SectionHeader>

      {wishlistData.lineItems && (
        <WishlistGrid lineItems={wishlistData.lineItems} cartManager={cartManager} wishlistManager={wishlistManager} />
      )}
    </section>
  );
}
