import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import ExpandableTextArea from '~/components/furniturechoice/expandable-text';

export interface CategoryHeaderProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  description?: string;
}

export default function CategoryHeader({ className, name, description, ...props }: CategoryHeaderProps): JSX.Element {
  const BREAK_AT = 139;
  const MIN_BREAK = 200;
  const READMORE = 'Read more >';
  const READLESS = 'Read less';

  return (
    <header className={clsx('container m-auto py-5 text-center', className)} {...props}>
      <h1 className="capitalize">
        <span className="block text-32 leading-[36px] text-grey-6 lg:text-48 lg:leading-[58px]">{name}</span>
      </h1>
      <span className="m-auto mb-4 mt-2 block h-px w-[42px] bg-salmon-4 lg:mb-6 lg:h-0.5 lg:w-[60px]"></span>
      <div className="text-12 leading-4 text-grey-5 lg:text-16 lg:leading-5">
        {description && (
          <ExpandableTextArea
            ellipsis={true}
            breakAt={BREAK_AT}
            minBreak={MIN_BREAK}
            readMore={READMORE}
            readLess={READLESS}
          >
            {description}
          </ExpandableTextArea>
        )}
      </div>
    </header>
  );
}
