'use client';

import { Account } from 'shared/types/account';
import { TasticProps } from '~/frontastic/tastics/types';
import { DataSourceV2 } from '~/types/datasource';
import WelcomeContent from './welcome-content';

interface AccountContentManagerProps {
  account: DataSourceV2<Account>;
}

export default function AccountContentManager({ data: { account } }: TasticProps<AccountContentManagerProps>) {
  return (
    <section className="standard-section-padding" data-testid="account-content-manager">
      <WelcomeContent account={account.dataSource} />
    </section>
  );
}
