'use client';

import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import UspCore from '~/components/furniturechoice/usp-core';
import { useCart } from '~/frontastic/hooks';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';
import { DataSourceV2 } from '~/types/datasource';

interface CartUSPProp {
  freeDeliveryImage?: ImageProps;
  freeReturnsImage?: ImageProps;
  financeImage?: ImageProps;
  financeTerms: DataSourceV2<FinanceProduct[]>;
}

export default function CartUSP({ data }: TasticProps<CartUSPProp>): JSX.Element {
  const cart = useCart();

  cart.data;

  return cart.data?.totalLineItemQuantity && cart.data?.totalLineItemQuantity > 0 ? (
    <section className="container mx-auto">
      <UspCore
        freeDeliveryImage={data.freeDeliveryImage}
        freeReturnsImage={data.freeReturnsImage}
        financeImage={data.financeImage}
        financeProducts={data.financeTerms}
        price={cart.data?.sum?.centAmount || 100000}
      />
    </section>
  ) : (
    <></>
  );
}
