import { FormEvent, useContext, useState } from 'react';
import { format } from 'date-fns';
import { Cart } from 'shared/types/cart';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import { sdk } from '~/sdk';
import getValidShippingMethods from './get-valid-shipping-methods';
import ShippingMethod from './shipping-method';

interface FormProps {
  cart: Cart;
}

export default function Form({ cart }: FormProps) {
  const [currentShippingMethodId, setCurrentShippingMethodId] = useState<string>(
    cart.shippingInfo?.shippingMethodId || '',
  );
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(
    cart.custom?.deliveryDate ? new Date(cart.custom.deliveryDate) : null,
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const validShippingMethods = getValidShippingMethods(cart);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setErrorMessage('');

    const formData = new FormData(event.currentTarget);
    const payload = Object.fromEntries(formData);
    const response = await executeWithProgress(() =>
      sdk.callAction({
        actionName: 'furniturechoice-cart/setShippingMethod',
        payload: {
          shippingMethodId: payload['shipping-method'],
          deliveryDate: payload['delivery-date'],
        },
      }),
    );

    if (response.isError) {
      setErrorMessage(
        formatMessage({ id: 'tastics.checkout.shipping.shipping-content-manager.delivery_date_is_invalid' }),
      );
    } else {
      // Don't use router.push to avoid prefetch/cache issue when going back and forth between the pages
      location.assign('/checkout/payment');
    }
  }

  function handleShippingMethodChange(id: string) {
    setCurrentShippingMethodId(id);
    setDeliveryDate(null);
  }

  return (
    <form className="flex flex-col gap-y-5" onSubmit={handleSubmit}>
      <h2 className="font-sans text-20 font-bold text-black">
        {formatMessage({ id: 'tastics.checkout.shipping.shipping-content-manager.delivery' })}
      </h2>

      <fieldset className="surface-grey-1 rounded-md px-4 py-6">
        <div>
          <legend className="sr-only">
            {formatMessage({ id: 'tastics.checkout.shipping.shipping-content-manager.select_delivery_method' })}
          </legend>

          <div className="flex flex-col gap-y-5">
            {validShippingMethods.map((shippingMethod) => (
              <ShippingMethod
                key={shippingMethod.shippingMethodId}
                cart={cart}
                shippingMethod={shippingMethod}
                isChecked={currentShippingMethodId === shippingMethod.shippingMethodId}
                onChange={handleShippingMethodChange}
                onDeliveryDateChange={(date) => setDeliveryDate(date)}
              />
            ))}
          </div>
        </div>
      </fieldset>

      <input type="hidden" name="delivery-date" value={deliveryDate ? format(deliveryDate, 'yyyy-MM-dd') : ''} />

      <FNCButton className="surface-green-2 rounded-sm lg:mt-4" type="submit">
        {formatMessage({ id: 'tastics.checkout.information.information-content-manager.continue' })}
      </FNCButton>

      {errorMessage && <p className="w-full px-4 text-center text-14 font-bold text-red-1">{errorMessage}</p>}
    </form>
  );
}
