'use client';

import { FormEvent, useContext, useId, useRef } from 'react';
import reactStringReplace from 'react-string-replace';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import FNCInput from '~/components/furniturechoice/fnc/fnc-input';
import Link from '~/components/furniturechoice/link';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import { sdk } from '~/sdk';
import { Reference } from '~/types/reference';

interface MailChimpNewsletterProps {
  newsletterHeader: string;
  newsletterSubhead: string;
  newsletterType: 'customer' | 'press_centre';
  newsletterImage: ImageProps;
  newsletterPrivacyLink: Reference;
  newsletterTnCLink: Reference;
}

export default function MailChimpNewsletter({
  data: {
    newsletterHeader,
    newsletterSubhead,
    newsletterType = 'customer',
    newsletterImage,
    newsletterPrivacyLink,
    newsletterTnCLink,
  },
}: TasticProps<MailChimpNewsletterProps>): JSX.Element {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const elRef = useRef<HTMLDivElement>(null);
  const messageId = useId();

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const payload = Object.fromEntries(formData);
    payload.type = newsletterType;

    await executeWithProgress(async () => {
      try {
        const res = await sdk.callAction({ actionName: 'furniturechoice-newsletter/add', payload });
        const messageEl = document.getElementById(`${messageId}`);

        if (!res.isError) {
          if (elRef.current) {
            elRef.current.classList.add('hidden');
            messageEl?.classList.remove('hidden');
            setTimeout(() => {
              messageEl?.classList.add('hidden');
            }, 5000);
          }
        }
      } catch (err) {
        console.error('Signup Error', err);
      }
    });
  }

  const subscribeDisclaimer = formatMessage({
    id: 'tastics.content.newsletter.subscribe_disclaimer',
  });

  const tncLink = (
    <Link className="cursor-pointer underline" key={useId()} reference={newsletterTnCLink}>
      {formatMessage({ id: 'tastics.content.newsletter.terms_and_conditions' })}
    </Link>
  );

  const privacyLink = (
    <Link className="cursor-pointer underline" key={useId()} reference={newsletterPrivacyLink}>
      {formatMessage({ id: 'tastics.content.newsletter.privacy_policy' })}
    </Link>
  );

  let parsedSubscribeDisclaimer;
  parsedSubscribeDisclaimer = reactStringReplace(subscribeDisclaimer, '{terms_and_conditions}', () => tncLink);
  parsedSubscribeDisclaimer = reactStringReplace(parsedSubscribeDisclaimer, '{privacy_policy}', () => privacyLink);

  return (
    <section className="standard-section-padding container" id="newsletter" data-testid="newsletter">
      <div
        ref={elRef}
        className="surface-salmon-1 flex flex-col items-center gap-y-4 p-6 lg:flex-row lg:gap-x-6 lg:px-28 lg:pb-0 lg:pt-8"
      >
        <div className="flex flex-1 items-center gap-x-7">
          {newsletterImage.media && (
            <CoFeImage
              className="hidden aspect-[127/174] w-[127px] rounded-t-lg lg:block"
              role="presentation"
              asset={newsletterImage.media}
              colSpanPercentLg={0.15}
              aspectRatio={127 / 174}
            />
          )}

          <div className="w-full text-center lg:text-left">
            <div className="flex flex-col gap-y-2 lg:max-w-[360px]">
              <h2 className="text-22 font-medium leading-6 lg:font-sans lg:text-20 lg:font-bold">{newsletterHeader}</h2>
              <p className="text-14 font-light leading-4.5 lg:leading-5">{newsletterSubhead}</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-y-1 lg:max-w-[415px]">
          <form className="flex flex-col gap-y-2 lg:flex-row" onSubmit={handleSubmit}>
            <FNCInput
              className="w-full [&>[role=alert]]:text-white [&>div:first-child]:h-[48px] lg:[&_input]:rounded-e-none"
              label="Enter your email"
              name="email"
              type="email"
              required
            />

            <FNCButton
              className="surface-grey-6 mb-1 min-h-[41px] rounded-md lg:h-12 lg:max-h-[48px] lg:rounded-l-none"
              type="submit"
            >
              {formatMessage({ id: 'tastics.content.newsletter.subscribe' })}
            </FNCButton>
          </form>

          <p className="text-center text-12 leading-4 lg:text-left"> {parsedSubscribeDisclaimer}</p>
        </div>
      </div>

      <div id={messageId} className="surface-salmon-1 hidden p-10 text-center font-bold">
        {formatMessage({ id: 'tastics.content.newsletter.subscribe_thank_you' })}
      </div>
    </section>
  );
}
