'use client';

import { useRef, useState, useEffect, useId } from 'react';
import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { SwiperContainer } from 'swiper/element/bundle';
import ContentfulImage from '~/components/furniturechoice/contentful-image';
import Link from '~/components/furniturechoice/link';
import SectionHeader from '~/components/furniturechoice/section-header';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import useProducts from '~/helpers/hooks/furniturechoice/use-products';
import useSwiper from '~/helpers/hooks/furniturechoice/use-swiper';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function RecentlyViewedProductSlider(): JSX.Element {
  const ref = useRef<SwiperContainer>(null);
  const { locale } = useParams();
  const [recentSKUs, setRecentSKUs] = useState<string[]>([]);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { data, isLoading } = useProducts({ skus: recentSKUs });
  const prevButtonId = useId();
  const nextButtonId = useId();

  useSwiper({
    ref,
    props: {
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 16,
          navigation: { enabled: false },
          scrollbar: { enabled: false },
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 32,
          navigation: {
            prevEl: `#${CSS.escape(prevButtonId)}`,
            nextEl: `#${CSS.escape(nextButtonId)}`,
            enabled: true,
          },
          scrollbar: { enabled: true },
        },
      },
    },
  });

  useEffect(() => {
    const fetchRecentProducts = async () => {
      const localStorageSKUs = JSON.parse(localStorage.getItem('recentSKUs') || '{}') as string[];

      if (!localStorageSKUs) return;

      setRecentSKUs(localStorageSKUs);
    };

    fetchRecentProducts();
  }, []);

  if (isLoading) return <div className="standard-section-padding" />;

  if (!data) return <></>;

  return (
    <section className="standard-section-padding">
      <div className="container">
        <SectionHeader as="h2">
          {formatMessage({ id: 'tastics.content.recently-viewed-product-slider.recently_viewed' })}
        </SectionHeader>

        <div className="hidden justify-end lg:flex lg:gap-x-2">
          <button
            id={prevButtonId}
            className="surface-salmon-4 flex aspect-square h-7 w-7 items-center justify-center rounded"
          >
            <MdChevronLeft size={24} />
          </button>
          <button
            id={nextButtonId}
            className="surface-salmon-4 flex aspect-square h-7 w-7 items-center justify-center rounded"
          >
            <MdChevronRight size={24} />
          </button>
        </div>

        <swiper-container
          // @ts-ignore: React doesn't support className for web components
          // eslint-disable-next-line tailwindcss/classnames-order
          class={clsx(
            'mt-10 flex h-[250px] overflow-x-hidden lg:mt-5 lg:h-[375px]',
            // eslint-disable-next-line prettier/prettier
            '[--swiper-scrollbar-bg-color:#e6e6e6] [--swiper-scrollbar-drag-bg-color:black] [--swiper-scrollbar-bottom:calc(0px)] [--swiper-scrollbar-sides-offset:0px] [--swiper-scrollbar-size:3px]',
          )}
          data-testid="recently-viewed-swiper"
          ref={ref}
          init={false}
          scrollbar
        >
          {data?.items.map(({ selectedVariant }) => (
            <swiper-slide
              // @ts-ignore: React doesn't support className for web components
              class="relative"
              data-testid="recently-viewed-slide-image"
              key={selectedVariant.sku}
            >
              <Link href={'/p/' + selectedVariant.slug}>
                {selectedVariant.discountedPrice && (
                  <div className="absolute left-0 top-0 flex h-[40px] w-[40px] items-center justify-center rounded-full bg-red-2">
                    <span className="text-center text-10 text-white">
                      {CurrencyHelpers.formatForCurrency(
                        {
                          fractionDigits: 0,
                          centAmount:
                            (selectedVariant.price?.centAmount || 0) -
                            (selectedVariant.discountedPrice.centAmount || 0),
                          currencyCode: selectedVariant.price?.currencyCode,
                        } || 0,
                        locale,
                      )}
                      <br />
                      {formatMessage({ id: 'tastics.content.recently-viewed-product-slider.off' })}
                    </span>
                  </div>
                )}

                <ContentfulImage
                  src={selectedVariant.images?.[0]}
                  alt={selectedVariant.attributes?.['descriptors_alt-title']}
                  className="aspect-square w-full rounded-md object-cover"
                  colSpanPercent={0.5}
                  colSpanPercentLg={0.25}
                />

                <div className="mt-3 flex flex-col items-baseline gap-y-3 lg:flex-row lg:gap-x-1">
                  <div
                    className={clsx('text-18 font-bold', {
                      'text-red-2': selectedVariant.discountedPrice,
                    })}
                    data-testid="main-price"
                  >
                    {CurrencyHelpers.formatForCurrency(
                      selectedVariant.discountedPrice || selectedVariant.price || 0,
                      locale,
                    )}
                  </div>

                  {selectedVariant.discountedPrice && (
                    <div className="text-13 text-grey-5 line-through" data-testid="compare-at-price">
                      {formatMessage({ id: 'tastics.content.recently-viewed-product-slider.was' })}{' '}
                      {CurrencyHelpers.formatForCurrency(selectedVariant.price || 0, locale)}
                    </div>
                  )}
                </div>
              </Link>
            </swiper-slide>
          ))}
        </swiper-container>
      </div>
    </section>
  );
}
