'use client';

import clsx from 'clsx';
import { MdArrowForward } from 'react-icons/md';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import Link from '~/components/furniturechoice/link';
import { ImageProps } from '~/frontastic/lib/image';
import { Reference } from '~/types/reference';

interface ArticleHighlightTileProps {
  title: string;
  link: Reference;
  image: ImageProps;
}

export default function ArticleHighlightTile({ title, link, image }: ArticleHighlightTileProps): JSX.Element {
  return (
    <div className="flex aspect-[375/620] w-full rounded-md lg:aspect-[292/471]" data-testid="article">
      <Link reference={link} className="relative">
        <CoFeImage
          className="m-auto object-cover brightness-75"
          asset={image.media}
          colSpanPercent={1.0}
          colSpanPercentLg={0.25}
          aspectRatio={375 / 620}
          aspectRatioLg={292 / 471}
        />
        <p
          className={clsx(
            'absolute inset-x-3 bottom-8',
            'font-playfair-display text-22 leading-[24px] text-white lg:text-center',
          )}
        >
          {title}
          <MdArrowForward size={24} className="relative ms-1 inline-block align-bottom" />
        </p>
      </Link>
    </div>
  );
}
