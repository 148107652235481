import Link from 'next/link';
import { useParams } from 'next/navigation';
import { Variant } from 'shared/types/product';
import CommercetoolsImage from '../commercetools-image';
import DynamicTag from '../dynamic-tag';
import VariantCardPricing from '../variant-card-pricing';
import VariantCardReviews from '../variant-card-reviews';
import VariantSticker from '../variant-sticker';

export type imageSizingType = {
  colSpanPercent?: number;
  colSpanPercentSm?: number;
  colSpanPercentMd?: number;
  colSpanPercentLg?: number;
  colSpanPercentXl?: number;
  colSpanPercent2Xl?: number;
};

interface VariantCardProps {
  headlineAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  variant: Variant;
  productName: string;
  imageSizing: imageSizingType;
}

export default function VariantCard({ headlineAs = 'h2', variant, productName, imageSizing }: VariantCardProps) {
  const { locale } = useParams();

  return (
    <Link className="relative flex flex-col gap-y-2" href={`/p/${variant.slug}`}>
      <VariantSticker size="small" variant={variant} className="-left-2.5 -top-2.5" />

      <div className="flex aspect-square overflow-hidden rounded bg-grey-2">
        <CommercetoolsImage className="w-full object-contain" src={variant.images?.[0]} {...imageSizing} />
      </div>

      <div className="flex flex-col gap-y-1">
        <DynamicTag className="font-playfair-display text-20 font-normal leading-5" as={headlineAs}>
          {variant.attributes?.['descriptors_alt-title']}
        </DynamicTag>

        <p className="line-clamp-2 text-12 leading-3.5">{productName}</p>
      </div>

      <VariantCardReviews variant={variant} />
      <VariantCardPricing className="mt-3" variant={variant} locale={locale} />
    </Link>
  );
}
