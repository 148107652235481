'use client';

import CoFeImage from '~/components/furniturechoice/co-fe-image';
import SectionHeader from '~/components/furniturechoice/section-header';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';

export interface Feature {
  image: ImageProps;
}

interface FeaturedInHighlightsProps {
  title: string;
  features: Feature[];
}

export default function FeaturedInHighlights({ data }: TasticProps<FeaturedInHighlightsProps>) {
  return (
    <section className="standard-section-padding container" data-testid="featured-in-highlights">
      <SectionHeader as="h2" className="mb-6 lg:mb-8" data-testid="featured-in-highlights-title">
        {data.title}
      </SectionHeader>

      <div className="flex flex-wrap justify-center">
        {data.features.map((feature, index) => (
          <CoFeImage
            className="aspect-[321/81] h-[81px] w-[321px] object-contain"
            asset={feature.image.media}
            aspectRatio={321 / 81}
            data-testid="featured-in-highlight"
            key={index}
          />
        ))}
      </div>
    </section>
  );
}
