import { useParams } from 'next/navigation';
import { Cart, ShippingMethod as ShippingMethodType } from 'shared/types/cart';
import FNCRadio from '~/components/furniturechoice/fnc/fnc-radio';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { useFormat } from '~/helpers/hooks/useFormat';
import CalendarManager from '../../../../../../../../components/furniturechoice/calendar-manager';

interface ShippingMethodProps {
  cart: Cart;
  shippingMethod: ShippingMethodType;
  isChecked: boolean;
  onChange(id: string): void;
  onDeliveryDateChange(date: Date | null): void;
}

export default function ShippingMethod({
  cart,
  shippingMethod,
  isChecked,
  onChange,
  onDeliveryDateChange,
}: ShippingMethodProps) {
  const { locale } = useParams();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const price = !shippingMethod.name?.toUpperCase().includes('NOTREADY')
    ? `${CurrencyHelpers.formatForCurrency(
        shippingMethod.rates?.[0].price || 0,
        locale,
        undefined,
        undefined,
        formatMessage({ id: 'tastics.checkout.shipping.shipping-content-manager.free' }),
      )} — `
    : '';

  return (
    <>
      <FNCRadio
        key={shippingMethod.shippingMethodId}
        className="flex-1 [&_label]:w-full [&_label]:font-semibold [&_label]:text-on-white"
        data-testid="card-method-field"
        label={`${price}${shippingMethod.custom.uiName}`}
        supportingText={shippingMethod.custom.uiDescription}
        name="shipping-method"
        value={shippingMethod.shippingMethodId}
        checked={isChecked}
        required
        onChange={() => onChange(shippingMethod.shippingMethodId)}
      />

      {isChecked && shippingMethod.custom.deliveryCalendar !== 'nocal' && (
        <CalendarManager
          shippingMethod={shippingMethod}
          defaultValue={cart.custom?.deliveryDate}
          onDeliveryDateChange={onDeliveryDateChange}
        />
      )}
    </>
  );
}
