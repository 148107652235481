'use client';

import { FinanceProduct } from 'shared/types/finance/FinanceProducts';
import FinanceCalculatorComponent from '~/components/furniturechoice/finance-calculator';
import FinanceLogo from '~/components/icons/finance-logo';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import { DataSourceV2 } from '~/types/datasource';

interface FinancialCalculatorProp {
  amountInitial: number;
  currency: string;
  financeDataSource: DataSourceV2<FinanceProduct[]>;
}

export default function FinanceCalculator({ data, params }: TasticProps<FinancialCalculatorProp>): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className="lg:mx-auto lg:w-[420px]" {...params}>
      <div className="mx-auto mb-6 flex items-center justify-center">
        <FinanceLogo className="mr-2" />
        <h4 className="text-18 font-bold leading-4.5">{formatMessage({ id: 'tastics.finance-calculator.header' })}</h4>
      </div>
      <div className="mx-4 rounded-md border border-grey-3 p-4 pt-8 shadow-equal">
        <FinanceCalculatorComponent
          amount={data.amountInitial}
          currencyCode={data.currency}
          configurableAmount={true}
          financeProducts={data.financeDataSource.dataSource}
        />
      </div>
    </div>
  );
}
