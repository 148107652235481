import { FormEvent, useContext, useState } from 'react';
import { useRouter } from 'next/navigation';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import FNCInput from '~/components/furniturechoice/fnc/fnc-input';
import { useAccount } from '~/frontastic/hooks';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';

interface FormProps {
  accountManager: ReturnType<typeof useAccount>;
}

export default function Form({ accountManager }: FormProps): JSX.Element {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { executeWithProgress, navigateWithProgress } = useContext(ProgressIndicatorContext);
  const [passwordInput, setPasswordInput] = useState('');

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const payload = Object.fromEntries(formData) as {
      email: string;
      currentPassword: string;
      newPassword: string;
      confirmNewPassword: string;
    };

    const account = await executeWithProgress(() =>
      accountManager.changePassword(payload.currentPassword, payload.newPassword),
    );

    if (account.accountId) {
      navigateWithProgress(() => router.push('/account'));
    } else {
      // TODO: Handle error here once generic error dialog is built
    }
  }

  return (
    <form className="flex flex-col gap-y-4" onSubmit={handleSubmit}>
      <h2 className="hidden font-sans text-20 font-bold lg:block">
        {formatMessage({ id: 'tastics.account.change-password-content-manager.change_password' })}
      </h2>
      <div className="flex flex-col gap-y-3 lg:w-1/2">
        <FNCInput type="email" name="email" label="Email" defaultValue={accountManager.account?.email} disabled />
        <FNCInput
          type="password"
          name="currentPassword"
          label={formatMessage({ id: 'tastics.account.change-password-content-manager.current_password' })}
          required
        />
        <FNCInput
          type="password"
          name="newPassword"
          onEveryChange={(e) => setPasswordInput(e.target.value)}
          label={formatMessage({ id: 'tastics.account.change-password-content-manager.new_password' })}
          required
        />
        <FNCInput
          type="password"
          name="confirmNewPassword"
          label={formatMessage({ id: 'tastics.account.change-password-content-manager.confirm_new_password' })}
          required
          pattern={`^${passwordInput}$`}
          patternFeedback={formatMessage({
            id: 'tastics.account.change-password-content-manager.password_mismatch_error',
          })}
        />

        <FNCButton className="surface-green-2 rounded-md" type="submit">
          {formatMessage({ id: 'tastics.account.change-password-content-manager.save_changes' })}
        </FNCButton>
      </div>
    </form>
  );
}
