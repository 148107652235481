'use client';

import { useEffect, useState } from 'react';
import FNCCheckbox from '~/components/furniturechoice/fnc/fnc-checkbox';

interface FilterTermCheckbox {
  label: string;
  filterKey: string;
  count: number;
  selected: boolean;
  isIndexable: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

export default function FilterTermCheckbox({
  filterKey,
  label,
  count,
  selected,
  onChange,
  disabled,
}: FilterTermCheckbox): JSX.Element {
  const [checkboxSelected, setCheckboxSelected] = useState<boolean>(selected);

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCheckboxSelected(!checkboxSelected);
    setTimeout(() => {
      onChange(e);
    }, 200);
  }

  useEffect(() => {
    setCheckboxSelected(selected);
  }, [selected]);

  return (
    <span className="flex w-full items-center justify-between">
      <FNCCheckbox
        className="[&_input]:cursor-pointer [&_label]:cursor-pointer [&_label]:capitalize"
        label={label}
        onChange={(e) => handleOnChange(e)}
        value={label}
        name={filterKey}
        checked={checkboxSelected}
        disabled={disabled}
      />
      <span className="mr-2">{count}</span>
    </span>
  );
}
