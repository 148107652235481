import { FilterEntry } from '../types/filter-entry';

export function addFilterValue(filterEntries: FilterEntry[], key: string, value: string): FilterEntry[] {
  const updatedEntries = filterEntries.map((entry) => ({ ...entry }));

  const index = updatedEntries.findIndex((entry) => entry.key === key);

  if (index !== -1) {
    const existingValues = updatedEntries[index].values;
    if (!existingValues.includes(value)) {
      updatedEntries[index].values.push(value);
    }
  } else {
    updatedEntries.push({ key, values: [value] });
  }

  return updatedEntries;
}
