'use client';

import { useCallback, useMemo } from 'react';
import { MdTune } from 'react-icons/md';
import { FNCProductPaginatedResult } from 'shared/types/result/PaginatedResult';
import SideModal from '~/components/furniturechoice/side-modal';
import { useFormat } from '~/helpers/hooks/useFormat';
import FilterFacetRange from './filter-facet-range';
import FilterFacetTerm from './filter-facet-term';
import ProductCardGrid from './product-card-grid';

export interface MainContentProps {
  products: FNCProductPaginatedResult;
  isMobileFilterOpen: boolean;
  setMobileFilterOpen: (param: boolean) => void;
  isDesktopFilterOpen: boolean;
  setDesktopFilterOpen: () => void;
  setDesktopFilterClose: () => void;
  handleFilterChange: (name: string, value: string, action: 'add' | 'replace' | 'remove') => void;
  activeSortOrder: string;
  handleSortChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  isLoading: boolean;
}

export default function MainContent({
  products,
  isMobileFilterOpen,
  setMobileFilterOpen,
  isDesktopFilterOpen,
  setDesktopFilterOpen,
  setDesktopFilterClose,
  handleFilterChange,
  activeSortOrder,
  handleSortChange,
  isLoading,
}: MainContentProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const onCheckboxFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const targetEl = event.target as HTMLInputElement;
      const { name, value, checked } = targetEl;

      if (checked) {
        handleFilterChange(name, value, 'add');
      } else {
        handleFilterChange(name, value, 'remove');
      }
    },
    [handleFilterChange],
  );

  const onRangeFilterChange = useCallback(
    (name: string, values: number[], initialMin: number, initialMax: number) => {
      const joinedVal = values.join('-');
      const initialJoinedVal = [initialMin, initialMax].join('-');

      if (joinedVal !== initialJoinedVal) {
        handleFilterChange(name, joinedVal, 'replace');
      }
    },
    [handleFilterChange],
  );

  const filterFacets = useMemo(() => {
    return products?.filterableFacets?.map((f) => {
      const title = formatMessage({ id: `tastics.category.collection.product-filter-titles.${f.identifier}` });

      if (f.type === 'term' || f.type === 'enum') {
        return (
          <FilterFacetTerm
            facet={f}
            key={f.identifier}
            title={title}
            onClick={onCheckboxFilterChange}
            disabled={isLoading}
          />
        );
      } else if (f.type === 'range') {
        return (
          <FilterFacetRange
            key={f.identifier}
            facet={f}
            title={title}
            onRangeChange={(values, initialMinVal, initialMaxVal) =>
              onRangeFilterChange(f.key, values, initialMinVal, initialMaxVal)
            }
          />
        );
      }

      return <></>;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products?.filterableFacets, onCheckboxFilterChange, onRangeFilterChange]);

  return (
    <>
      <ProductCardGrid
        pagedProducts={products}
        activeSortOrder={activeSortOrder}
        onSortChange={handleSortChange}
        filterFacets={filterFacets}
        isFilterOpen={isDesktopFilterOpen}
        onFilterOpen={isDesktopFilterOpen ? setDesktopFilterClose : setDesktopFilterOpen}
      />
      <SideModal
        className="lg:hidden"
        isOpen={isMobileFilterOpen}
        onModalClose={() => setMobileFilterOpen(!isMobileFilterOpen)}
      >
        <div className="px-2 py-0.5">
          <h5 className="mb-4 flex items-center font-sans font-medium">
            <MdTune size={15} className="mr-1.5" />
            {formatMessage({ id: 'tastics.category.collection.product-card-grid.filter_products' })}
          </h5>
          <div className="[&>div]:border-t [&>div]:border-t-grey-3 [&>div]:py-4">{filterFacets}</div>
        </div>
      </SideModal>
    </>
  );
}
