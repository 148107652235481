'use client';

import CategoryHeader from '~/components/furniturechoice/category-header';
import { TasticProps } from '~/frontastic/tastics/types';

export interface CollectionHeaderProps {
  name: string;
  description: string;
}

export default function CollectionHeader({ data }: TasticProps<CollectionHeaderProps>): JSX.Element {
  return <CategoryHeader name={data.name} description={data.description} />;
}
