'use client';

import { useAccount } from '~/frontastic/hooks';
import Form from './form';

export default function SignInContentManager(): JSX.Element {
  const accountManager = useAccount();

  return (
    <section className="standard-section-padding">
      <Form accountManager={accountManager} />
    </section>
  );
}
