import React from 'react';
import FacebookSVG from '~/components/furniturechoice/svg/Facebook.svg';
import InstagramSVG from '~/components/furniturechoice/svg/Instagram.svg';
import PinterestSVG from '~/components/furniturechoice/svg/Pinterest.svg';
import TiktokSVG from '~/components/furniturechoice/svg/Tiktok.svg';
import TwitterSVG from '~/components/furniturechoice/svg/Twitter.svg';

type Props = {
  type: string;
};

export default function SocialMediaIcon({ type }: Props) {
  switch (type) {
    case 'Facebook':
      return (
        <div data-testid={type} data-social-icon="facebook" className="w-6">
          <FacebookSVG />
        </div>
      );
    case 'Pinterest':
      return (
        <div data-testid={type} data-social-icon="pinterest" className="w-6">
          <PinterestSVG />
        </div>
      );
    case 'Tiktok':
      return (
        <div data-testid={type} data-social-icon="tiktok" className="w-6">
          <TiktokSVG />
        </div>
      );
    case 'Instagram':
      return (
        <div data-testid={type} data-social-icon="instagram" className="w-6">
          <InstagramSVG />
        </div>
      );
    case 'Twitter':
      return (
        <div data-testid={type} data-social-icon="twitter" className="w-6">
          <TwitterSVG />
        </div>
      );
    default:
      return <span></span>;
  }
}
