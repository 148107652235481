import clsx from 'clsx';
import { MdKeyboardArrowDown } from 'react-icons/md';
import DynamicTag from '~/components/furniturechoice/dynamic-tag';

interface HeadingProps {
  headingId: string;
  headingTag: string;
  headingText: string;
  panelId: string;
  isExpanded: boolean;
  onClick(): void;
}

export default function Heading({
  headingId,
  headingTag,
  headingText,
  panelId,
  isExpanded,
  onClick,
}: HeadingProps): JSX.Element {
  return (
    <DynamicTag as={headingTag} data-testid="heading">
      <button
        className="flex w-full items-center justify-between overflow-hidden"
        data-testid="button"
        type="button"
        id={headingId}
        aria-expanded={isExpanded}
        aria-controls={panelId}
        onClick={onClick}
      >
        <span className="font-sans text-16 font-medium">{headingText}</span>
        <MdKeyboardArrowDown
          className={clsx('fill-grey-5 text-24', {
            'rotate-180': isExpanded,
          })}
        />
      </button>
    </DynamicTag>
  );
}
