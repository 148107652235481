import { HTMLAttributes } from 'react';

interface LogoProps extends HTMLAttributes<SVGElement> {
  primaryFillColor?: string;
  accentFillColor?: string;
}

export default function Logo({
  primaryFillColor = 'fill-salmon-1',
  accentFillColor = 'fill-black',
  ...props
}: LogoProps): JSX.Element {
  return (
    <svg width="168" height="43" viewBox="0 0 168 43" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        className={accentFillColor}
        d="M149.883 19.3993C146.192 19.4662 146.862 17.8585 146.172 14.0661C145.628 11.7214 144.775 10.1441 142.415 9.53265C145.026 9.22893 147.569 7.93106 147.562 4.5578C147.562 0.730987 143.828 0.060788 140.825 0.0648375L131.319 0.131655V0.676317C133.652 0.676317 133.876 0.846398 133.882 2.57352L133.913 17.2571C133.913 19.126 133.724 19.3831 131.36 19.3872V19.9541L139.785 19.9359V19.369C137.393 19.369 137.197 19.1462 137.195 17.4191L137.177 9.71083H140.41C142.328 9.72703 143.02 10.8406 143.334 14.7464C143.625 19.3183 144.325 20.6486 149.89 19.9845V19.4054L149.883 19.3993ZM139.96 9.22893H137.154V2.70716C137.154 1.06305 137.401 0.6095 138.624 0.607475H140.151C142.93 0.607475 144.388 1.98634 144.388 4.65701C144.386 8.51824 142.961 9.22286 139.954 9.22893H139.96Z"
      />
      <path
        className={accentFillColor}
        d="M22.1438 12.8978L22.1214 2.695C22.1214 0.994195 21.8242 0.710727 19.6782 0.716801V0.178212L28.2824 0.162015V0.700602C25.7069 0.700602 25.5603 1.10555 25.5643 2.57554L25.5888 13.3433C25.5888 17.0304 27.0934 19.2353 31.1614 19.2272C35.3169 19.2272 36.683 16.2103 36.6769 12.6973L36.6586 4.53349C36.6586 0.876759 36.4143 0.686429 33.5191 0.686429V0.147842L40.071 0.133667V0.680355C37.6094 0.680355 37.4058 0.882831 37.414 4.47477L37.4343 13.0922C37.4466 17.7694 35.1947 20.3813 30.1943 20.3813C24.9983 20.4016 22.154 17.9982 22.1438 12.8978Z"
      />
      <path
        className={accentFillColor}
        d="M80.773 19.452C83.1144 19.452 83.4341 19.2495 83.432 17.49L83.3995 2.66868C83.3995 0.846386 83.1612 0.686428 80.7302 0.690478V0.153916L89.4485 0.133667V0.672256C87.0806 0.672256 86.8444 0.905103 86.8485 2.51884L86.881 17.5689C86.881 19.2414 87.2679 19.4378 89.4912 19.4338V20.0007L80.773 20.0189V19.452Z"
      />
      <path
        className={accentFillColor}
        d="M95.5707 19.4519C98.2644 19.4519 98.6248 19.2333 98.6248 17.3644L98.6634 2.59777C98.6634 0.977952 98.5515 0.698535 96.77 0.692461C92.9015 0.680312 92.14 4.16494 91.8652 7.70828H91.2788L91.4152 0.141724L109.379 0.192343L109.477 7.76092H108.894C108.64 4.21758 107.889 0.728907 104.024 0.718784C102.182 0.718784 102.123 1.02452 102.119 2.55525L102.078 17.4636C102.078 19.2211 102.424 19.4783 105.144 19.4884V20.0533L95.5748 20.027L95.5707 19.4519Z"
      />
      <path
        className={accentFillColor}
        d="M112.793 12.8655L112.771 2.66265C112.771 0.963869 112.474 0.680401 110.328 0.684451V0.147889L118.932 0.129665V0.668254C116.356 0.668254 116.21 1.07321 116.214 2.54319L116.238 13.3129C116.238 16.998 117.743 19.205 121.811 19.1847C125.966 19.1847 127.333 16.1678 127.326 12.6549L127.308 4.49101C127.308 0.846433 127.064 0.643956 124.169 0.643956V0.115492L130.72 0.101318V0.639906C128.263 0.639906 128.059 0.842382 128.074 4.44242L128.092 13.0598C128.104 17.737 125.852 20.349 120.854 20.349C115.648 20.3713 112.805 17.9679 112.793 12.8655Z"
      />
      <path
        className={accentFillColor}
        d="M60.4068 32.613C60.3925 26.4638 64.6274 22.2563 70.3833 22.2462C72.0206 22.2301 73.6342 22.6368 75.0661 23.4266C75.6219 23.7627 75.9742 24.0219 76.2674 24.0179C76.7051 23.9591 76.8782 23.3942 76.9657 22.5438H77.5215C77.5562 25.0667 77.6193 27.6159 77.6254 30.082H77.0349C77.0044 29.9687 77.0044 29.7702 76.9759 29.6022C76.2938 25.4372 74.0929 22.805 70.3792 22.8131C66.0771 22.8131 64.2711 27.2777 64.2834 32.6049C64.2956 37.7903 66.0913 42.1537 71.0633 42.1436C74.8096 42.1436 77.2894 39.1064 77.375 34.901H78.0122V35.3059C77.8432 39.2725 75.7461 42.9899 70.479 43C64.4361 43 60.421 38.7278 60.4068 32.613Z"
      />
      <path
        className={accentFillColor}
        d="M79.4557 41.9795C81.7951 41.9795 82.1168 41.777 82.1147 40.0175L82.0842 25.1941C82.0842 23.3719 81.848 23.2099 79.417 23.2159V22.6774L88.0782 22.6571V23.1957C85.7124 23.1957 85.5332 23.4285 85.5373 25.0443L85.5515 31.3353L94.0356 31.317L94.0214 25.1678C94.0214 23.4083 93.6671 23.1856 91.4723 23.1896V22.6531L100.133 22.6328V23.1714C97.7676 23.1714 97.4724 23.3739 97.4744 25.018L97.5091 39.9527C97.5091 41.7952 97.8043 41.9369 100.176 41.9309V42.4978L91.515 42.5181V41.9511C93.8544 41.9511 94.06 41.7162 94.056 39.7927L94.0397 32.0257L85.5535 32.0419L85.5719 40.0924C85.5719 41.7648 85.8976 41.9612 88.121 41.9592V42.5343L79.4597 42.5545L79.4557 41.9795Z"
      />
      <path
        className={accentFillColor}
        d="M100.911 32.4672C100.897 26.6298 104.867 22.1712 110.605 22.1591C116.342 22.1469 120.3 26.7047 120.312 32.5988C120.327 38.2681 116.358 42.8947 110.651 42.9069C104.769 42.917 100.925 38.3349 100.911 32.4672ZM116.42 32.4672C116.42 28.5553 115.285 22.6896 110.576 22.6997C105.69 22.7118 104.794 28.4946 104.804 32.4611C104.814 36.4276 105.794 42.346 110.621 42.3399C115.505 42.3318 116.428 36.4033 116.42 32.4631V32.4672Z"
      />
      <path
        className={accentFillColor}
        d="M121.882 41.9877C124.224 41.9877 124.543 41.7852 124.541 40.0257L124.507 25.2023C124.507 23.3801 124.27 23.2181 121.839 23.2242V22.6916L130.564 22.6714V23.21C128.196 23.21 127.96 23.4428 127.964 25.0586L127.996 40.1046C127.996 41.7771 128.381 41.9755 130.604 41.9694V42.5344L121.882 42.5546V41.9877Z"
      />
      <path
        className={accentFillColor}
        d="M131.684 32.459C131.669 26.3098 135.904 22.1023 141.66 22.0922C143.297 22.0767 144.911 22.4834 146.343 23.2727C146.899 23.6088 147.249 23.8679 147.542 23.8639C147.982 23.8052 148.153 23.2403 148.243 22.3899H148.798C148.833 24.9107 148.898 27.4619 148.9 29.9281H148.318C148.287 29.8147 148.287 29.6163 148.259 29.4482C147.577 25.2833 145.376 22.6511 141.662 22.6592C137.36 22.6592 135.554 27.1137 135.566 32.4509C135.566 37.6364 137.372 41.9997 142.346 41.9896C146.091 41.9896 148.572 38.9423 148.658 34.747H149.299V35.152C149.132 39.1205 147.035 42.8461 141.766 42.8461C135.713 42.8542 131.698 38.584 131.684 32.459Z"
      />
      <path
        className={accentFillColor}
        d="M18.8597 0.1073L2.27026 0.14172V0.680309C4.61169 0.680309 4.93134 0.870637 4.93745 2.57347L4.97206 17.3684C4.97206 19.2393 4.65444 19.4378 2.31302 19.4438V20.0108L11.5891 19.9905V19.4236C8.77942 19.4236 8.42922 19.2332 8.42515 17.332L8.40683 10.138V9.7047H10.0194C12.9146 9.7047 13.5905 10.3769 13.7147 13.9567H14.3011L14.2807 5.11252H13.6944C13.6455 8.59917 12.9125 9.13979 9.95624 9.14789H8.40275V8.71256L8.60635 2.7152C8.60635 0.892909 8.60635 0.672211 11.0496 0.668161H11.9556C15.9055 0.668161 17.6565 1.58943 18.2571 5.44256C18.2571 5.55595 18.3772 6.2059 18.3772 6.31929H18.933C18.933 6.31929 18.8618 0.824067 18.8597 0.1073Z"
      />
      <path
        className={accentFillColor}
        d="M166.996 13.3655C166.422 18.1845 164.553 19.4074 159.929 19.4175H159.08C157.179 19.4175 156.505 19.3102 156.503 17.3827L156.486 9.87279V9.70068H158.622C161.491 9.70068 162.138 10.5734 162.287 13.9527H162.871L162.851 5.10851H162.269C162.189 8.49797 161.489 9.12565 158.563 9.12565H156.488V8.95759L156.474 2.88329C156.474 0.899017 156.617 0.672243 158.899 0.639847H160.096C164.136 0.639847 165.744 1.56112 166.341 5.32921C166.341 5.32921 166.487 6.06622 166.487 6.12494H167.017C166.986 5.44462 166.799 0.769433 166.74 0.0891113L150.356 0.121507V0.660096C152.695 0.660096 153.017 0.862571 153.023 2.49656L153.056 17.3482C153.056 19.1705 152.769 19.4175 150.397 19.4236V19.9906L167.426 19.9541C167.426 19.3285 167.499 13.3493 167.499 13.3493L166.996 13.3655Z"
      />
      <path
        className={accentFillColor}
        d="M167.501 35.9032C166.927 40.7221 165.058 41.9451 160.434 41.9573H159.585C157.683 41.9573 157.012 41.8479 157.007 39.9203L156.991 32.4105V32.2384H159.127C161.996 32.2384 162.645 33.1131 162.792 36.4904H163.374L163.356 27.6462H162.771C162.69 31.0498 161.99 31.6735 159.066 31.6816H156.989V31.5135L156.975 25.4392C156.975 23.4549 157.117 23.2282 159.4 23.1958H160.599C164.636 23.1958 166.245 24.117 166.841 27.8851C166.841 27.8851 166.988 28.6221 166.988 28.6808H167.517C167.487 28.0005 167.3 23.3253 167.241 22.645L150.857 22.6774V23.216C153.196 23.216 153.518 23.4084 153.524 25.0525L153.556 39.9041C153.556 41.7264 153.269 41.9735 150.897 41.9795V42.5465L167.927 42.51C167.927 41.8844 168 35.9052 168 35.9052L167.501 35.9032Z"
      />
      <path
        className={accentFillColor}
        d="M79.2338 0.674248V0.133634L72.7674 0.119461V0.660075C75.3715 0.660075 75.7217 0.923295 75.7176 2.74559L75.6932 15.2465C72.1607 10.3972 68.3635 5.23605 64.6987 0.101238L58.7942 0.0870651V0.621604C61.0481 0.621604 61.3983 0.797758 61.3942 2.64637L61.3698 15.0501C61.325 17.901 61.1438 19.373 58.4399 19.4884H58.2058C55.2617 19.3122 55.8115 17.6661 55.1721 14.1187C54.6245 11.7558 53.7632 10.1623 51.3566 9.53868C54.0198 9.24104 56.6279 7.94721 56.6299 4.54965C56.6299 0.702593 52.8389 0.0060743 49.7706 0L40.0628 0.125536V0.672223C42.1335 0.672223 42.6751 0.759288 42.671 2.49451L42.6404 17.2753C42.6404 19.1583 42.4369 19.4135 40.0242 19.4114V19.9804L48.6243 19.9986V19.4276C46.1811 19.4276 45.9877 19.1948 45.9877 17.4535L46.004 9.69256L49.3023 9.70471C51.263 9.72901 51.9634 10.8548 52.2667 14.7849C52.5192 19.0267 54.201 20.5089 58.102 20.2315C61.893 20.0047 62.1028 17.3077 62.1089 15.7628V15.0541C62.1231 11.0289 62.1516 2.6484 62.1516 2.10778C66.3519 7.95531 71.0185 14.3435 75.2778 20.1626H76.5625L76.5992 2.74963C76.6073 0.898995 77.0451 0.672223 79.2338 0.674248ZM48.8422 9.22889H45.9816L46.0101 2.66459C46.0101 1.01036 46.2707 0.554786 47.5188 0.556811H49.0804C51.9308 0.556811 53.4008 1.97415 53.3886 4.64887C53.3784 8.53439 51.9125 9.24711 48.8462 9.23901L48.8422 9.22889Z"
      />
      <path
        className={primaryFillColor}
        d="M0.00203645 42.0747C1.84667 42.0747 2.28441 41.6697 3.71777 38.0252C5.03304 34.6539 8.16443 26.5184 9.80343 22.3514H11.0882C13.2524 28.3043 15.3638 34.1417 17.526 40.0378C18.202 41.7953 18.6377 42.0787 19.953 42.0787V42.5343H12.1754V42.0808C14.7489 42.0808 15.1011 41.8256 14.2806 39.4729C13.8734 38.3107 13.3726 36.8407 13.1099 36.1016H5.12262C4.88644 36.7232 4.51182 37.7457 4.18605 38.6508C3.19248 41.4855 3.16805 42.0808 6.55394 42.0808V42.5343H0V42.0808L0.00203645 42.0747ZM12.9063 35.5003C11.7641 32.2728 10.4203 28.4399 9.27814 25.2124C8.05653 28.4399 6.55801 32.2991 5.35676 35.5003H12.9063Z"
      />
      <path
        className={primaryFillColor}
        d="M18.1123 42.0767C20.5128 42.0767 20.7591 41.7406 20.7591 40.0924V25.097C20.7591 23.2545 20.4069 23.0864 18.1551 23.0864V22.6612H23.5098C27.2235 27.7049 31.5846 33.516 35.2128 38.3916V25.1537C35.2128 23.3314 34.8606 23.0844 32.2565 23.0844V22.6592H38.5132V23.0844C36.3184 23.0844 35.8766 23.3132 35.8766 25.182V42.67H34.94C30.6114 36.8589 25.6354 30.1711 21.3659 24.447V40.1491C21.3659 41.6252 21.541 42.0767 24.1756 42.0767V42.5343H18.1184V42.0808L18.1123 42.0767Z"
      />
      <path
        className={primaryFillColor}
        d="M38.5134 42.0768C40.9119 42.0768 41.2071 41.8743 41.2071 39.7827V25.0161C41.2071 23.2566 40.8854 23.0885 38.5134 23.0885V22.6633H47.8791C53.9078 22.6633 57.5359 26.4598 57.5359 32.4409C57.5359 38.5598 54.1114 42.5324 47.8526 42.5324H38.5134V42.0768ZM54.1663 32.5827C54.1663 27.9642 53.0547 23.0885 47.3192 23.0885C44.3364 23.0885 44.1023 23.3173 44.1023 25.1315V39.9832C44.1023 41.769 44.3649 42.0809 47.3192 42.0809C53.2888 42.0809 54.1663 37.3692 54.1663 32.5827Z"
      />
    </svg>
  );
}
