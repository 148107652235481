'use client';

import { FormEvent, useContext, useEffect, useId, useRef, useState } from 'react';
import clsx from 'clsx';
import { MdClose } from 'react-icons/md';
import reactStringReplace from 'react-string-replace';
import FNCInput from '~/components/furniturechoice/fnc/fnc-input';
import Link from '~/components/furniturechoice/link';
import { TasticProps } from '~/frontastic/tastics/types';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';
import { sdk } from '~/sdk';
import { Reference } from '~/types/reference';

interface StickyMailChimpNewsletterProps {
  newsletterHeader: string;
  newsletterSubhead: string;
  newsletterType: 'customer' | 'press_centre';
  newsletterPrivacyLink: Reference;
  newsletterTnCLink: Reference;
}

export default function StickyMailChimpNewsletter({
  data: { newsletterHeader, newsletterSubhead, newsletterType = 'customer', newsletterPrivacyLink, newsletterTnCLink },
}: TasticProps<StickyMailChimpNewsletterProps>): JSX.Element {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const elSticky = useRef<HTMLDivElement>(null);
  const elAnchor = useRef<HTMLDivElement>(null);
  const [isDocked, setIsDocked] = useState(false);
  const [isDockingEnabled, setIsDockingEnabled] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [placeholderHeight, setPlaceholderHeight] = useState(0);

  const storageKey = 'newsletterDockingDisabled';
  function checkFloatingStateOpen() {
    if (localStorage.getItem(storageKey)) {
      return localStorage.getItem(storageKey) === 'true' ? false : true;
    } else {
      return true;
    }
  }

  const subscribeDisclaimer = formatMessage({
    id: 'tastics.content.newsletter.subscribe_disclaimer',
  });

  const tncLink = (
    <Link className="cursor-pointer underline" key={useId()} reference={newsletterTnCLink}>
      {formatMessage({ id: 'tastics.content.newsletter.terms_and_conditions' })}
    </Link>
  );

  const privacyLink = (
    <Link className="cursor-pointer underline" key={useId()} reference={newsletterPrivacyLink}>
      {formatMessage({ id: 'tastics.content.newsletter.privacy_policy' })}
    </Link>
  );

  let parsedSubscribeDisclaimer;
  parsedSubscribeDisclaimer = reactStringReplace(subscribeDisclaimer, '{terms_and_conditions}', () => tncLink);
  parsedSubscribeDisclaimer = reactStringReplace(parsedSubscribeDisclaimer, '{privacy_policy}', () => privacyLink);

  function hideFloatingNewsletter() {
    localStorage.setItem(storageKey, 'true');
    setIsDockingEnabled(false);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const payload = Object.fromEntries(formData);
    payload.type = newsletterType;

    await executeWithProgress(async () => {
      try {
        const res = await sdk.callAction({ actionName: 'furniturechoice-newsletter/add', payload });

        if (!res.isError) {
          if (elSticky.current) {
            setIsSubmitted(true);
            setTimeout(() => {
              hideFloatingNewsletter();
              setIsDocked(false);
              setIsSubmitted(false);
            }, 5000);
          }
        }
      } catch (err) {
        console.error('Signup Error', err);
      }
    });
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsDocked(!entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: [0],
      },
    );

    if (elAnchor.current) {
      observer.observe(elAnchor.current);
    }

    return () => {
      if (elAnchor.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(elAnchor.current);
      }
    };
  }, []);

  useEffect(() => {
    if (elSticky.current) {
      setPlaceholderHeight(elSticky.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    const isOpen = checkFloatingStateOpen();
    setIsDockingEnabled(isOpen);
  }, []);

  return (
    <section>
      <div
        ref={elAnchor}
        className={clsx({ '!h-0': !isDocked || !isDockingEnabled })}
        style={{ height: placeholderHeight }}
      ></div>
      <div
        ref={elSticky}
        className={clsx('fixed bottom-0 w-full rounded-t-md bg-salmon-1', {
          'container !relative !rounded-none bg-transparent': !isDocked || !isDockingEnabled,
        })}
      >
        {!isSubmitted ? (
          <div
            className={clsx('flex bg-salmon-1 p-5 text-white lg:px-12', {
              'flex-col rounded-t-md p-4 lg:flex-row lg:items-center lg:justify-between lg:px-9': isDocked,
              'flex-col lg:flex-row': !isDocked,
            })}
          >
            {/* Headers */}
            <div
              className={clsx(
                'flex flex-1 flex-col text-left',
                { 'text-center lg:mt-6 lg:text-left': !isDocked },
                { 'items-baseline lg:basis-[66%] lg:flex-row': isDocked },
              )}
            >
              {isDocked && (
                <button
                  className="absolute right-4 top-4 ml-4 cursor-pointer p-2 lg:hidden"
                  onClick={() => hideFloatingNewsletter()}
                >
                  <MdClose size={20} />
                </button>
              )}

              <h5
                className={clsx('mb-2 font-bold leading-6 lg:text-21', {
                  'text-21 lg:mb-0 xl:ml-10': isDocked,
                  'text-20 lg:mb-2 lg:ml-0': !isDocked,
                })}
              >
                {newsletterHeader}
              </h5>
              <p
                className={clsx('text-16 leading-4.5 lg:text-left lg:text-16', {
                  'lg:mx-6': isDocked,
                  'text-16': !isDocked,
                })}
              >
                {newsletterSubhead}
              </p>
            </div>

            {/* Inputs + Footer */}
            <div className={clsx('mt-4 flex-1 lg:w-full', { 'basis-[33%]': isDocked })}>
              <div className="relative">
                {/* Inputs */}
                <form onSubmit={handleSubmit}>
                  <div className="flex w-full">
                    <FNCInput
                      className={clsx(
                        'mb-2 w-full lg:w-3/4 [&>[role=alert]]:mb-2 [&>[role=alert]]:text-12 [&>[role=alert]]:text-white [&>div:first-child]:h-[48px]',
                        { 'lg:w-[calc(100%-84px)] [&_input]:rounded-e-none': isDocked },
                        { 'lg:[&_input]:rounded-e-none': !isDocked },
                      )}
                      name="email"
                      label="Enter your email"
                      type="email"
                    />
                    {isDocked && (
                      <>
                        <button
                          className={clsx(
                            'surface-grey-6 mb-1  h-[48px] w-auto rounded rounded-s-none p-2 px-6 text-center text-14 font-semibold leading-5 lg:right-[20%]',
                          )}
                        >
                          {formatMessage({ id: 'tastics.content.newsletter.subscribe' })}
                        </button>
                        <button
                          className="-mt-1 ml-7 hidden cursor-pointer lg:z-100 lg:block"
                          onClick={() => hideFloatingNewsletter()}
                        >
                          <MdClose size={20} />
                        </button>
                      </>
                    )}
                  </div>

                  {!isDocked && (
                    <button
                      className={clsx(
                        'surface-grey-6 mb-1 w-full rounded p-2 text-center text-14 font-semibold leading-5',
                        'lg:absolute lg:right-0 lg:top-0 lg:h-[48px] lg:w-1/4 lg:rounded-s-none',
                      )}
                    >
                      {formatMessage({ id: 'tastics.content.newsletter.subscribe' })}
                    </button>
                  )}
                </form>
                <p
                  className={clsx('text-12 font-light leading-[15px] lg:text-left', {
                    'lg:hidden': isDocked,
                    'text-center lg:!block': !isDocked,
                  })}
                >
                  {parsedSubscribeDisclaimer}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="surface-salmon-1 p-10 text-center font-bold">
            {formatMessage({ id: 'tastics.content.newsletter.subscribe_press_centre_thank_you' })}
          </div>
        )}
      </div>
    </section>
  );
}
