import { FNCOrder } from 'shared/types/furniturechoice/order';
import { useFormat } from '~/helpers/hooks/useFormat';

interface PaymentMethodProps {
  paymentMethodInfo: FNCOrder['metafields']['paymentMethodInfo'];
}

export default function PaymentMethod({ paymentMethodInfo }: PaymentMethodProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const humanReadableMethod: Record<string, string> = {
    creditCardWithPayerAuthentication: 'Credit/Debit Card',
  };

  return (
    <section className="flex flex-col gap-y-3 border-t border-solid border-grey-3 py-8">
      <h3 className="font-sans font-semibold">
        {formatMessage({ id: 'tastics.account.order-detail-content-manager.payment_method' })}
      </h3>

      <div className="surface-grey-1 flex flex-col gap-y-3 rounded-md p-4">
        <p className="text-14 leading-5 text-grey-4" data-testid="payment-data">
          {`${paymentMethodInfo.paymentInterface} - ${
            humanReadableMethod[paymentMethodInfo.method] || paymentMethodInfo.method
          }`}
        </p>
      </div>
    </section>
  );
}
