'use client';

import Link from '~/components/furniturechoice/link';
import Logo from '~/components/furniturechoice/logo';
import PaymentMethod from '~/components/furniturechoice/payment-methods';
import SectionHeader from '~/components/furniturechoice/section-header';
import DigicertIcon from '~/components/icons/digicert';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import Steps from './steps';

interface CheckoutHeaderProps {
  step?: '1' | '2' | '3' | '4';
}

export default function CheckoutHeader({ data: { step } }: TasticProps<CheckoutHeaderProps>) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <header className="container">
      <div className="flex items-end py-5">
        <Link className="w-[168px] lg:w-[156px]" href="/">
          <Logo className="w-full" />
        </Link>

        <Link className="ml-auto text-14 text-salmon-4" href="/">
          {`< ${formatMessage({ id: 'tastics.checkout.checkout-header.continue_shopping' })}`}
        </Link>
      </div>

      <div className="flex flex-col gap-y-5 py-10 lg:items-center lg:gap-y-4 lg:pb-8">
        <SectionHeader as="h1">
          {formatMessage({ id: 'tastics.checkout.checkout-header.secure_checkout' })}
        </SectionHeader>

        <div className="flex">
          <PaymentMethod className="flex gap-2" />
          <DigicertIcon className="ml-auto lg:hidden" />
        </div>
      </div>

      {step && <Steps currentStep={parseInt(step) as 1 | 2 | 3 | 4} />}
    </header>
  );
}
