'use client';

import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';

interface ExpandableTextProps {
  header?: string;
  text?: string;
}

export default function ExpandableTextArea({ data }: TasticProps<ExpandableTextProps>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [summary, setSummary] = useState('');
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const fullText = data.text || '';

  /* Extract expandable text from data.text. Admin will be able to specify how much text to 
  show using custom [summary]...[/summary]*/
  useEffect(() => {
    const startIndex = fullText.indexOf('[summary]');
    const endIndex = fullText.indexOf('[/summary]');

    if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
      const extractedSummary = fullText.substring(startIndex + '[summary]'.length, endIndex).trim();
      setSummary(extractedSummary);
    } else {
      setSummary(fullText);
    }
  }, [fullText]);

  /* Set the state of text to be not expanded by default.*/
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  /* Preprocess text to remove [summary] and [/summary] tags if text is expanded */
  const processedText = isExpanded ? fullText.replace(/\[\/?summary\]/g, '') : summary;

  return (
    <section className="container max-w-[910px]">
      {data.header && (
        <h1 className="mx-auto my-4 max-w-[700px] text-center font-playfair-display text-36 uppercase leading-none">
          {data.header}
        </h1>
      )}
      <ReactMarkdown className="flex flex-col gap-4 [&_a]:text-salmon-4">{processedText}</ReactMarkdown>
      <button className="mt-2 text-14 text-salmon-4" onClick={toggleText}>
        {isExpanded
          ? formatMessage({ id: 'tastics.content.expandable-text-area.show_less' })
          : formatMessage({ id: 'tastics.content.expandable-text-area.show_more' })}
      </button>
    </section>
  );
}
