'use client';

import { HTMLAttributes, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { MdClose, MdOutlineWarningAmber } from 'react-icons/md';
import FNCButton from '~/components/furniturechoice/fnc/fnc-button';
import { useFormat } from '~/helpers/hooks/useFormat';

interface LoginErrorModal extends HTMLAttributes<HTMLDialogElement> {
  isOpen: boolean;
  onModalClose: () => void;
}

export default function LoginErrorModal({ isOpen, onModalClose, ...props }: LoginErrorModal): JSX.Element {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  useEffect(() => {
    const dialogEl = dialogRef.current as unknown as HTMLDialogElement;
    if (isOpen) {
      dialogEl.show();
    } else {
      dialogEl.close();
    }
  }, [isOpen]);

  return (
    <>
      <dialog
        ref={dialogRef}
        className={clsx('fixed inset-0 z-600 items-center justify-center bg-transparent backdrop:bg-backdrop', {
          hidden: !isOpen,
        })}
        inert={!isOpen ? '' : undefined}
        onClick={(event) => event.target === dialogRef.current && onModalClose()}
        data-testid="login-error-modal"
        {...props}
      >
        <div className="flex h-[246px] w-[309px] rounded-md bg-white p-3 scrollbar-hide lg:h-[223.5px] lg:w-[574px]">
          <button
            className="pointer-events-auto absolute right-0 top-0 cursor-pointer rounded rounded-r-none bg-white p-2"
            onClick={onModalClose}
            aria-label="close"
          >
            <MdClose size={24} />
          </button>
          <div className="flex h-full w-full flex-col gap-6 p-3 lg:gap-5">
            <MdOutlineWarningAmber size={48} className="fill-salmon-5" />
            <h2 className="text-18 font-bold">
              {formatMessage({ id: 'tastics.checkout.customer.login-error-modal.header' })}
            </h2>
            <div className="w-full border-b-2 border-grey-3" />
            <div dir="rtl" className="grid w-full grid-cols-1 gap-2 lg:grid-cols-2">
              <FNCButton className="surface-grey-4 h-[48px] rounded-lg font-light" onClick={onModalClose}>
                {formatMessage({ id: 'tastics.checkout.customer.login-error-modal.close' })}
              </FNCButton>
            </div>
          </div>
        </div>
      </dialog>

      {isOpen && <div className="fixed inset-0 z-[595] bg-backdrop" onClick={onModalClose} />}
    </>
  );
}
