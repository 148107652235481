'use client';

import { useRef, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { PaypalReturnData } from 'shared/types/payment/paypal';
import { TasticProps } from '~/frontastic/tastics/types';
import { DataSourceV2 } from '~/types/datasource';

interface PaypalNotificationHandlerProps {
  data: DataSourceV2<PaypalReturnData>;
}

export default async function PaypalNotificationHandler({
  data,
  searchParams,
}: TasticProps<PaypalNotificationHandlerProps>) {
  const initialized = useRef(false);
  const router = useRouter();

  try {
    useEffect(() => {
      if (!searchParams['token']) {
        router.push('/');
      }

      if (!searchParams['PayerID']) {
        router.push('/checkout/payment/');
      }

      if (!initialized.current) {
        initialized.current = true;
        if (data.data.dataSource.error) {
          console.log(data.data.dataSource.message);
          router.push('/checkout/payment/?error=' + data.data.dataSource.message);
        } else {
          router.push(
            '/orderacknowledge/' +
              data.data.dataSource.paymentid +
              '/?token=' +
              data.data.dataSource.token +
              '&PayerID=' +
              data.data.dataSource.payerid,
          );
        }
      }
    });
    return (
      <div>
        <div className='fixed inset-x-0 top-0 z-1200 h-1 overflow-hidden bg-salmon-3 after:block after:h-full after:w-full after:animate-[progress-indicator_2s_infinite] after:bg-salmon-1 after:content-[""]' />
      </div>
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    console.log(e.message);
    return <></>;
  }
}
